<template>
  <div class="cja-dropdown-set">
    <div class="cja-dropdown">
      <fieldset class="" :class="focus ? 'focusing' : ''">
        <span>{{ $t('bb.results.filter.' + keyName + '.' + selected) }}</span>
        <select @focus="focus = true" @blur="focus = false" ref="select" v-model="selected" @change="updateValue">
          <option v-for="option in options" :key="option" :value="option"> {{ $t('bb.results.filter.' + keyName + '.' +
              option)
          }} </option>
        </select>
        <em class="m-cgg-icon--uniE00A"></em>
      </fieldset>
    </div>
  </div>
</template>

<script>
import screenSizeDetector from '../../../../mixins/screenSizeDetector.js'
import gtmEvents from '../../../../mixins/gtm/gtmEventsBBNew'

export default {
  props: ['keyName', 'options', 'value'],
  data() {
    return {
      selected: this.value || this.options[0],
      focus: false
    }
  },
  watch: {
    value: function () {
      this.selected = this.value
    }
  },
  methods: {
    /**
     * Emits the input event to the parent and
     * launches the GTM Event Product Search
     */
    updateValue() {
      const val = this.selected
      this.$emit('input', val)
      this.sendProductSearch(this.keyName, this.selected)
    }
  },
  mixins: [screenSizeDetector, gtmEvents]
}
</script>

<style lang="scss" scoped>
$top-line-color: #CCCAC8;
$active-border-color: #77AA43;

.cja-dropdown-set {
  padding-top: 2rem;
  padding-bottom: 2rem;

  .cja-dropdown {
    fieldset {
      padding: 5px 15px;
      height: 35px;
      border: 1px solid $top-line-color;
      border-radius: 3px;
      background-color: #fff;
      position: relative;
      width: 100%;
      min-width: 0;

      &.focusing {
        box-shadow: inset 0 0 0 1px $active-border-color;
        border-color: $active-border-color;
      }

      span {
        overflow: hidden;
        width: 172px;
        max-width: calc(100% - 23px);
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
      }

      em {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-trabsform: translateY(-50%);
        right: 15px;
        color: $top-line-color;
      }

      select {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}
</style>
