import Vue from "vue";
import VueI18n from "vue-i18n";
import VueRouter from "vue-router";
import VueGtm from "vue-gtm";
import axios from "axios";
import $ from "jquery";
import App from "./App.vue";
import "./directives/popover";
import "./directives/number";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/colors.sass";
import "./assets/css/iconia.scss";
import "./assets/css/tooltip.scss";
import Vuelidate from "vuelidate";
import bbNewData from "./store/bbNewData.js";
import Vuex from "vuex";

Vue.use(Vuex);
Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(Vuelidate);
// eslint-disable-next-line
Vue.use(VueGtm, { id: "GTM-WD8TWC", enabled: CookieInformation.getConsentGivenFor('cookie_cat_statistic'), debug: true, });
var storeWithFilter;

storeWithFilter = new Vuex.Store({
  modules: {
    bbNewData,
  },
});

Vue.prototype.$http = axios;
Vue.prototype.$env = process.env;

const i18n = new VueI18n({
  locale: "pt",
  fallbackLocale: "pt",
});

const router = new VueRouter({
  mode: "history",
  routes: [],
});

$.ajaxSetup({
  async: false,
});

$.getJSON(
  process.env.VUE_APP_CJA_URL + "/Internationalization?contains=results,widget",
  function (data) {
    i18n.setLocaleMessage("pt", data);
  }
);

export const eventBus = new Vue();

new Vue({
  el: "#app",
  i18n,
  store: storeWithFilter,
  router,
  render: (h) => h(App),
});
