<template>
  <div class="switch-group">
    <div v-for="option in options" :key="option.category">
      <div class="category" @click="toggleShown(option.category)">
        <span>{{ $t('bb.results.filter.' + keyName + '.' + option.category) }}<em
            :class="isShown[option.category] ? 'm-cgg-icon--chevron-up' : 'm-cgg-icon--chevron-down'"></em></span>
      </div>
      <transition name="slide">
        <div v-show="isShown[option.category]">
          <div class="cja-toggle" v-for="item in option.items" :key="item">
            <label class="switch" :class="{ 'selected': value.includes(item) }">
              <input type="checkbox" ref="switch" :name="item" :checked="value.includes(item)"
                @change="updateValue(item)">
              <span class="tick m-cgg-icon--boxes-tick"></span>
            </label>
            <span class="label">{{ $t(label(option.category, item)) }}</span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import gtmEvents from '../../../../mixins/gtm/gtmEvents'
import gtmEventsMg from '../../../../mixins/gtm/gtmEventsMg'

export default {
  props: ['keyName', 'options', 'value'],
  mixins: [gtmEvents, gtmEventsMg],
  data() {
    return {
      values: {},
      isShown: {}
    }
  },
  watch: {
    value: function () {
      this.getVModel()
    }
  },
  created() {
    this.getVModel()
    this.initializeShown()
    this.emitInput()
  },
  methods: {
    /**
     * Updates the value list to the parent to see.
     * 
     * Emits an input event to the parent and
     * launches the GTM Event Product Search.
     * 
     * @param {String} option The keyName of the option selected
     */
    updateValue(option) {
      this.values[option].value = !this.values[option].value
      this.emitInput()
      this.sendProductSearch(option, this.values[option].value)
    },
    /** 
     * Returns the indicate label to translate for the given option
     * 
     * @param {String} option The keyName of the option
     * @return {String} The label for the given option
     */
    label(option, item) {
      return 'bb.results.filter.' + this.keyName + '.' + option + '.' + item
    },
    /**
     * Constructs the list with the selected options and sends
     * it to the parent, emits the input event.
     * 
     */
    emitInput() {
      var res = []
      for (let v in this.values) {
        if (this.values[v].value) {
          res.push(this.values[v].key)
        }
      }
      this.$emit('input', res)
    },
    /**
     * Fetches the options passed by the parent props, and 
     * constructs the list of Objects to store the values
     */
    getVModel() {
      for (let i = 0; i < this.options.length; i++) {
        for (let j = 0; j < this.options[i].items.length; j++) {
          var res = {
            key: this.options[i].items[j],
            value: this.value.includes(this.options[i].items[j])
          }
          this.values[this.options[i].items[j]] = res
        }
      }
    },
    initializeShown() {
      for (let i = 0; i < this.options.length; i++) {
        this.$set(this.isShown, this.options[i].category, false)
      }
    },
    toggleShown(category) {
      this.$set(this.isShown, category, !this.isShown[category])
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors.sass";
$top-border-color: #CCCAC8;
$slider-color: #d8e0f3;
$switch-color: #98bf70;

.switch-group {
  min-height: 110px;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .category {
    cursor: pointer;
    padding: 1rem 0;

    em {
      float: right;
    }
  }

  .cja-toggle {
    width: 100%;
    vertical-align: middle;
    padding-top: 1rem;
    display: flex;

    .switch {
      position: relative;
      display: inline-block;
      width: 2.5rem;
      min-width: 2.5rem;
      height: 2.5rem;
      margin-right: 1rem;
      background-color: transparent;
      border-radius: 0.5rem;
      border: solid 1px $grey5;
      cursor: pointer;

      &.selected {
        border-color: $green1;
        background-color: $green1;
      }

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .tick {
        color: white;
        line-height: 2.4rem;
        position: absolute;
        left: 0.4rem;
      }
    }

    .label {
      word-break: break-word;
    }
  }
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

;
</style>
