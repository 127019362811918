<template>
  <div :id="'modal-checkout-' + _uid" class="modal fade">
    <div
      class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header align-items-center text-center">
          <h1 class="modal-title w-100">{{ title }}</h1>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div
                v-if="step === 1"
                class="col-12 col-lg-6 order-2 order-lg-1 left"
              >
                <div class="row">
                  <div class="col-12">
                    <p class="title">
                      <em class="m-cgg-icon--phone"></em>
                      {{ $t("bb.results.checkoutModal.phoneCheckout.title") }}
                    </p>
                  </div>
                </div>
                <div class="row pt-4">
                  <div class="col-12">
                    <p class="description">
                      <em class="m-cgg-icon--checkmark"></em>
                      {{
                        $t(
                          "bb.results.checkoutModal.phoneCheckout.description1"
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <p class="description">
                      <em class="m-cgg-icon--checkmark"></em>
                      {{
                        $t(
                          "bb.results.checkoutModal.phoneCheckout.description2"
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div class="row pt-4">
                  <div class="col-12">
                    <div class="input-group">
                      <input
                        :id="'input-lead-capture-' + _uid"
                        class="cja-input form-control"
                        :class="{ 'cja-invalid-input': $v.name.$error }"
                        :placeholder="
                          $t('bb.results.checkoutModal.phoneCheckout.name')
                        "
                        type="text"
                        v-model="name"
                        @blur="$v.name.$touch()"
                      />
                    </div>
                    <p
                      v-if="
                        $v.name.$dirty && $v.name.$invalid && !$v.name.required
                      "
                      class="cja-validation-error-message"
                    >
                      {{
                        $t(
                          "bb.results.checkoutModal.phoneCheckout.name.error.required"
                        )
                      }}
                    </p>
                    <p
                      v-else-if="
                        $v.name.$dirty && $v.name.$invalid && !$v.name.name
                      "
                      class="cja-validation-error-message"
                    >
                      {{
                        $t(
                          "bb.results.checkoutModal.phoneCheckout.name.error.invalid"
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div class="row pt-4">
                  <div class="col-3 pr-2">
                    <input
                      class="cja-input form-control"
                      id="countryCode"
                      disabled
                      type="text"
                      v-model="countryCode"
                    />
                  </div>
                  <div class="col-9 pl-2">
                    <div class="input-group">
                      <input
                        :id="'input-lead-capture-' + _uid"
                        v-number
                        inputmode="numeric"
                        maxlength="9"
                        class="cja-input form-control cja-phone-input"
                        :class="{ 'cja-invalid-input': $v.phone.$error }"
                        :placeholder="
                          $t('bb.results.checkoutModal.phoneCheckout.phone')
                        "
                        v-model="phone"
                        @blur="$v.phone.$touch()"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <p
                      v-if="
                        $v.phone.$dirty &&
                        $v.phone.$invalid &&
                        !$v.phone.required
                      "
                      class="cja-validation-error-message"
                    >
                      {{
                        $t(
                          "bb.results.checkoutModal.phoneCheckout.phone.error.required"
                        )
                      }}
                    </p>
                    <p
                      v-else-if="
                        $v.phone.$dirty &&
                        $v.phone.$invalid &&
                        (!$v.phone.numeric ||
                          !$v.phone.minLentgh ||
                          !$v.phone.maxLength ||
                          !$v.phone.phone)
                      "
                      class="cja-validation-error-message"
                    >
                      {{
                        $t(
                          "bb.results.checkoutModal.phoneCheckout.phone.error.invalid"
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div class="row pt-5">
                  <div class="col-12">
                    <button @click="handleSubmitInfo" class="btn-cta">
                      {{
                        $t("bb.results.checkoutModal.phoneCheckout.cta.title")
                      }}
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="step === 1"
                class="col-12 col-lg-6 order-1 order-lg-2 right"
              >
                <div class="row">
                  <div class="col-12">
                    <p class="title">
                      <em class="m-cgg-icon--online"></em>
                      {{ $t("bb.results.checkoutModal.onlineCheckout.title") }}
                    </p>
                  </div>
                </div>
                <div class="row pt-4">
                  <div class="col-12">
                    <p class="description">
                      <em class="m-cgg-icon--checkmark"></em>
                      {{
                        $t(
                          "bb.results.checkoutModal.onlineCheckout.description1"
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <p class="description">
                      <em class="m-cgg-icon--checkmark"></em>
                      {{
                        $t(
                          "bb.results.checkoutModal.onlineCheckout.description2"
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div class="row pt-5 bottom">
                  <div class="col-12">
                    <button @click="goToCheckout()" class="btn-cta">
                      {{
                        $t("bb.results.checkoutModal.onlineCheckout.cta.title")
                      }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="step === 2" class="col-12 center">
                <div class="row align-items-center">
                  <div class="col-12 col-md-7 order-2 order-md-1">
                    <div class="row">
                      <div
                        class="col-12 col-md-10"
                        :class="{ 'center-text': isMobile || isTablet }"
                      >
                        <span class="title">{{
                          $t(
                            "bb.results.checkoutModal.phoneCheckout.finishTitle"
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="row pt-4">
                      <div
                        class="col-12 col-md-10"
                        :class="{ 'center-text': isMobile || isTablet }"
                      >
                        <span class="description">{{
                          $t(
                            "bb.results.checkoutModal.phoneCheckout.finishDescription"
                          )
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-5 order-1 order-md-2">
                    <div class="col-12 center-text">
                      <img
                        :src="image_path + 'bb_checkout-phone_checkout.svg'"
                        alt=""
                        class="attachment"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="step === 2" class="modal-footer">
          <div class="w-100">
            <div class="float-right" :class="{ 'w-100': isMobile }">
              <button
                @click="hide"
                type="button"
                class="btn btn-cta"
                :class="{ 'btn-block': isMobile }"
              >
                {{ $t("bb.results.checkoutModal.phoneCheckout.finishCTA") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import screenSizeDetector from "../../mixins/screenSizeDetector";
import {
  required,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
import { eventBus } from "../../main";
import { mapGetters } from "vuex";

import gtmEvents from "../../mixins/gtm/gtmEventsBBNew";

export default {
  data() {
    return {
      product: {},
      index: undefined,
      step: 1,
      showDetails: true,
      countryCode: "+351",
      sentToSalesForce: false,
      image_path: undefined,
    };
  },
  mixins: [screenSizeDetector, gtmEvents],
  validations: {
    name: {
      required,
    },
    phone: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(9),
      numeric,
      phone(phone) {
        if (typeof phone === "undefined" || phone === null || phone === "") {
          return true;
        }
        return /^((\+351|00351|351)?)(2\d{1}|(9(3|6|2|1)))(?!(\d)\6{6})(\d)(\d{6})/.test(
          phone
        );
      },
    },
  },
  methods: {
    show(product, index) {
      this.product = product;
      this.index = index;
      $("#modal-checkout-" + this._uid).modal("show");
    },
    hide() {
      $("#modal-checkout-" + this._uid).modal("hide");
    },
    toggleDetails() {
      if (this.isMobile) {
        this.showDetails = !this.showDetails;
      }
    },
    handleSubmitInfo() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var request = {
          cggId: this.product.providerId,
          data: {
            name: this.name,
            phone: this.phone,
            terms: true,
            privacy: true,
            provider: this.product.providerId,
          },
          journeyId: this.data.journeyId,
          viewId: this.product.viewId,
        };
        request.data = JSON.stringify(request.data);
        this.$http.put(this.leadCapturePopupEndpoint, request);
        this.sendGTMSimpleEvent("formSubmit", "number submission", 0, []);
        this.step = 2;
      }
    },
    applyProductData(action, intent) {
      var postRequest = {
        action,
        category: this.data.category,
        cggId: this.product.cggId,
        intent,
        journeyId: this.data.journeyId,
        path: this.$route.path,
        position: this.index,
        viewId: this.product.viewId,
        providerCggId: this.product.provider.cggId,
      };
      this.$http.post(
        process.env.VUE_APP_CJA_URL + this.applyProductDataEndpoint,
        postRequest
      );
    },
    goToCheckout() {
      this.applyProductData("OPEN_CHECKOUT", "apply");
      this.sendGTMSimpleEvent("formSubmit", "online checkout", 0, []);
      window.open(
        "/tv-net-voz/aplicar?journeyId=" +
          this.data.journeyId +
          "&cggId=" +
          this.product.cggId +
          `&provider=${this.product.provider.cggId}`,
        "_self"
      );
    },
  },
  created() {
    eventBus.$on("setLeadCapture", (product) => {
      this.product = product;
      this.thankYou = false;
      this.$v.$reset();
      if (this.name && this.phone) {
        this.submit();
      }
    });
  },
  computed: {
    ...mapGetters([
      "fileInputHasError",
      "fileName",
      "leadCapturePopupEndpoint",
      "data",
      "applyProductDataEndpoint",
    ]),
    name: {
      get() {
        return this.$store.getters.name;
      },
      set(val) {
        this.$store.dispatch("setName", val);
      },
    },
    phone: {
      get() {
        return this.$store.getters.phone;
      },
      set(val) {
        this.$store.dispatch("setPhone", val);
      },
    },
    title() {
      var prod = "default";

      if (this.product && this.product.provider) {
        prod = this.product.provider.cggId;
      }

      return this.$t("bb.results.checkoutModal.title." + prod);
    },
  },
  mounted() {
    this.image_path = process.env.IMG_ENV_ACCESS;
    var vm = this;

    $("#modal-checkout-" + this._uid).on("hidden.bs.modal", function () {
      vm.step = 1;
    });
  },
};
</script>

<style lang="scss" scoped>
.modal {
  font-size: 1.5rem;

  .modal-content {
    border-radius: 9px;

    .modal-header {
      padding: 2rem 6rem;
      color: #312f2e;

      .modal-title {
        font-weight: 700;
      }

      button.close {
        color: #cccbc8;
        opacity: 1;

        span {
          font-size: 5rem;
        }
      }
    }

    .modal-body {
      overflow-x: hidden;
      font-size: 2rem;
      padding: 0;

      .right {
        background-color: #eef4e8;

        .bottom {
          position: absolute;
          bottom: 45px;
          width: calc(100% - 40px);
        }
      }

      .left,
      .right {
        padding: 45px 35px;

        .title {
          display: flex;
          font-size: 25px;
          line-height: 30px;
          font-weight: bold;
          color: #77aa43;

          em {
            margin-right: 10px;
          }
        }

        .description {
          display: flex;
          font-size: 16px;
          line-height: 20px;
          color: #312f2e;

          em {
            font-size: 20px;
            margin-right: 10px;
            color: #77aa43;
          }
        }

        .btn-cta {
          background-color: #77aa43;
          border: none;
          border-radius: 7px;
          color: white;
          font-size: 18px;
          line-height: 22px;
          font-weight: bold;
          padding: 15px 0;
          width: 100%;
        }
      }

      .center {
        padding: 9.1rem 5.6rem;

        .title {
          display: flex;
          font-size: 25px;
          line-height: 30px;
          font-weight: bold;
          color: #77aa43;
        }

        .description {
          display: flex;
          font-size: 16px;
          line-height: 20px;
          color: #312f2e;
        }
      }
    }

    .modal-footer {
      padding: 3rem 4rem;

      .btn.btn-cta {
        background-color: #076b9c;
        border: none;
        border-radius: 7px;
        color: white;
        font-size: 18px;
        line-height: 22px;
        font-weight: bold;
        padding: 15px 30px;
      }
    }
  }
}

.form-control {
  box-shadow: none;
}

.cja-label {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
  color: black;
}

.cja-input {
  color: #312f2e;
  font-size: 2rem;
  font-weight: 400;
  border: 1.5px solid #cccbc8;
  border-radius: 5px;
  display: inline;
  padding-left: 15px;
  height: 56px;

  &::placeholder {
    font-weight: 700;
  }
}

.cja-input:disabled {
  background-color: rgb(231, 231, 231);
}

.cja-invalid-input {
  border: solid 1px red;
  background-color: rgb(255, 235, 235) !important;
}

.cja-validation-error-message {
  color: red;
  padding: 5px 0px 0px 5px;
  font-size: 1.5rem;
}

.attachment {
  height: 280px;
  width: 100%;
}

@media (max-width: 991.8px) {
  .modal {
    .modal-content {
      .modal-body {
        .left,
        .right {
          .bottom {
            position: relative;
            width: auto;
            bottom: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 767.8px) {
  .modal {
    .modal-content {
      height: 100%;

      .modal-header {
        button.close {
          color: #979290;
        }
      }

      .modal-body {
        .center {
          .title {
            justify-content: center;
          }

          .description {
            justify-content: center;
          }
        }
      }

      .modal-footer {
        padding: 2rem 5rem;
      }
    }
  }
} ;
</style>
