import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      "data",
      "sorting",
      "providers",
      "premiumPackagesOptions",
      "tvFeatureOptions",
      "mainInternetUsageOptions",
      "providersList"
    ]),
    /**
     * Creates the filter JSON used in the GTM Events payload, accordingly to the filters state.
     * 
     * @returns {Object} The filter object.
     */
    filterJSON() {
      var filter = {}
      filter.downloadSpeed = eval(this.data.downloadSpeed)
      filter.avgMonthlyCost = eval(this.data.avgMonthlyCost)
      
      for(let i in this.mainInternetUsageOptions) {
        filter[this.mainInternetUsageOptions[i]] = this.data.mainInternetUsage.includes(this.mainInternetUsageOptions[i])
      }

      for(let i in this.tvFeatureOptions) {
        filter[this.tvFeatureOptions[i]] = this.data.tvFeature.includes(this.tvFeatureOptions[i])  
      }

      for(let i in this.premiumPackagesOptions) {
        filter[this.premiumPackagesOptions[i]] = this.data.premiumPackages.includes(this.premiumPackagesOptions[i])
      }

      filter.tvImportant = this.data.tvImportant
      filter.landline = this.data.landline

      var providers = []
      for(let i in this.data.provider) {
        providers.push(this.providersList[this.data.provider[i]].name)
      }

      filter.providers = providers

      return filter
    },
    /**
     * Creates the sort JSON used in the GTM Events payload, accordingly to the sort state.
     * 
     * @returns {Object} The sort object.
     */
    sortJSON() {
      var sort = {}

      sort.filter = this._i18n.t('widget.sort-menu.' + this.sorting.activeColumn)
      
      if(this.data.sortBy[0].slice(-1) == '-') {
        sort.direction = 'desc'
      } else {
        sort.direction = 'asc'
      }

      return sort
    },
    /**
     * Creates the default payload to send to GTM.
     * 
     * @return {Object} The default payload.
     */
    payload() {
      var comingFromFunnel = this.data.comingFromFunnel ? this.data.comingFromFunnel == 1 : false 

      return {
        event: "Deco_Results_BB",
        eventDetails: {
          category: "broadband",
          location: "Result Page",
          action: "compare",
          properties: {
            list: this.data.category,
            sort: this.sortJSON,
            filter: this.filterJSON
          },
          updatedFilter: '',  
          loadTime: 0,
          orientation: 'horizontal',
          throughFunnel: comingFromFunnel,
          listDesign: 'Skinny',
          afiliateName: undefined,
          instanceName: undefined,
          locale: undefined
        }
      }
    }
  },
  methods: {
    /**
     * Creates the eCommerce impressions list to add to the default GTM payload.
     * 
     * @param {Array} products The list of products 
     * 
     * @returns {Array} The impression  list
     */
    createEcommerceProducts(products) {
      var list = []
      for(let index in products) {
        let product = products[index]
        var eProduct = {
          name: product.description,
          id: product.cggId,
          brand: product.name,
          position: eval(index) + 1,
          dimension3: "none",
          dimension4: product.banner.bannerType,
          dimension5: product.showCTALeadCapture,
          dimension6: "service:" + product.offer.service + 
            ", noOfChannels:" + product.tv.noOfChannels + 
            ", maxSpeedDownload:" + product.speed.maxSpeedDownload + 
            ", publicPrice:" + Number.parseFloat(product.fee.publicPrice).toFixed(2),
          category: "broadband",
          list: this.data.category,
          price: "5.00",
          quantity: 1
        }
        list.push(eProduct)
      }
      return list
    },
    /**
     * Constructs the payload and emits an productSearch GTM Event.
     * 
     * @param {String} keyName The filter keyName.
     * @param {*} value The filter value.
     */
    sendProductSearch(keyName, value) {
      let updFilterJSON = {}
      updFilterJSON[keyName] = value
      updFilterJSON["filterType"] = keyName

      var payload = this.payload
      payload.event = "productSearch"
      payload.eventDetails.properties.updatedFilter = updFilterJSON
      payload['ecommerce'] = {'impressions': []}

      this.$gtm.trackEvent(payload)
    },
    /**
     * Creates the payload and emits a ProductSearch GTM Event when the user resets the filters.
     * 
     * @param {Array} values An array with all the values changed.
     */
    sendProductSearchReset(values) {
      var filter = values
      filter["filterType"] = "Reset"

      var payload = this.payload
      payload.event = "productSearch"
      payload.eventDetails.properties.updatedFilter = filter
      payload['ecommerce'] = {'impressions': []}

      this.$gtm.trackEvent(payload)
    },
    /**
     * Creates the payload and sends a generic GTM Event.
     * 
     * @param {String} name The name of the event.
     * @param {String} action The action of the event.
     * @param {Number} time The time it took the products to load.
     * @param {Array} products The list of products loaded.
     */
    sendGTMSimpleEvent(name, action, time, products) {
      var payload = this.payload
      if(action) {
        payload.action = action
      }
      payload.event = name
      payload.eventDetails.loadTime = time || 0

      payload['ecommerce'] = {
        'impressions': this.createEcommerceProducts(products)
      }

      this.$gtm.trackEvent(payload)
    },
    /**
     * Creates the payload and sends an productDetail GTM Event.
     * 
     * @param {Object} product The product in wich the user clicked.
     * @param {Number} index The index of the product.
     */
    sendDetail(product, index) {
      var payload = this.payload
      payload.action = "detail"
      payload.event = "productDetail"
      payload.eventDetails.loadTime = 0

      var ecommerce = {
        detail : {
          actionField: {
            list: this.data.category,
            action: "detail"
          },
          products: [
            {
              name: product.description,
              id: product.cggId,
              brand: product.name,
              position: index,
              dimension3: "none",
              dimension4: product.banner.bannerType,
              dimension5: product.showCTALeadCapture,
              dimension6: "service:" + product.offer.service + 
                ", noOfChannels:" + product.tv.noOfChannels + 
                ", maxSpeedDownload:" + product.speed.maxSpeedDownload + 
                ", publicPrice:" + Number.parseFloat(product.fee.publicPrice).toFixed(2),
              category: "broadband",
              list: this.data.category,
              price: "5.00",
              quantity: 1
            }
          ] 
        }
      }

      payload['ecommerce'] = ecommerce

      this.$gtm.trackEvent(payload)
    },
    /**
     * Creates the payload and sends an purchase GTM Event.
     * 
     * @param {String} source The place where the user clicked in the result component.
     * @param {Object} product The product in wich the user clicked.
     * @param {Number} index The index of the product.
     */
    sendPurchase(source, product, index) { 
      var payload = this.payload
      payload.event = "purchase"
      payload.eventDetails.label = product.description
      payload.label = product.description
      payload.action = "Conversion - " + source

      var ecommerce = {
        purchase : {
          actionField: {
            id: Math.floor(Math.random()*(999999999999-Math.pow(10,11)) + Math.pow(10,11)),
            revenue: "5.00",
            action: "purchase"
          },
          products: [
            {
              name: product.description,
              id: product.cggId,
              brand: product.name,
              position: index,
              dimension3: source,
              dimension4: product.banner.bannerType,
              dimension5: product.showCTALeadCapture,
              dimension6: "service:" + product.offer.service + 
                ", noOfChannels:" + product.tv.noOfChannels + 
                ", maxSpeedDownload:" + product.speed.maxSpeedDownload + 
                ", publicPrice:" + Number.parseFloat(product.fee.publicPrice).toFixed(2),
              category: "broadband",
              list: this.data.category,
              price: "5.00",
              quantity: 1
            }
          ] 
        }
      }

      payload['ecommerce'] = ecommerce

      this.$gtm.trackEvent(payload)
    }
  }
}
