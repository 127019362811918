import Vue from 'vue'

Vue.directive('number', {
  bind(el, binding, vnode) {
    var inputHandler = function (e) {
      if(el.value.length > 1 && el.value[0] == '0') {
        el.value = el.value.substr(1)
      }
      if (isNaN(Number(el.value))) {
        el.value = el.value.replace(/[^0-9]/g, '')
      }
    }
    el.addEventListener("input", inputHandler)
  }
})