import Vue from 'vue'
import $ from 'jquery'
import 'bootstrap'
import 'popper.js'

Vue.directive('popover', function(el, binding) {
  
  var placement = binding.arg ? binding.arg : 'auto'
  var content = binding.value
  var trigger = 'click'
  
  if(!$.isEmptyObject(binding.modifiers)) {
    trigger = Object.keys(binding.modifiers).join(' ')
  }

  if(content) {
    $(el).popover( {
      trigger,
      content,
      placement
    })
  }
  
})