<template>
  <div class="cja-filter-panel" :class="filterOpen ? 'open' : 'closed'">
    <div
      v-if="isTablet && !isMobile && filterOpen"
      class="modal-background"
      @click="toggleFilterPanel"
    ></div>
    <div class="header d-none d-xl-table" @click="toggleFilterPanel">
      <span v-if="filterOpen" class="title">
        {{ $t("widget.filter-panel.title") }}
      </span>
      <span v-if="!filterOpen" class="title m-cgg-icon--filter-panel"></span>
      <span
        class="icon"
        :class="
          !filterOpen ? 'm-cgg-icon--full-arrow-down' : 'm-cgg-icon--cross'
        "
      ></span>
    </div>
    <transition name="slide">
      <div
        v-if="filterOpen"
        class="content"
        :class="{
          open: filterOpen,
          tablet: isTablet,
          mobile: isMobile,
        }"
      >
        <div class="d-flex d-md-none row content-header">
          <div class="col-2">
            <span
              ><em
                @click="toggleFilterPanel"
                class="m-cgg-icon--arrow-back"
              ></em
            ></span>
          </div>
          <div class="col-10">
            <span>{{ $t("widget.filter-panel.title") }}</span>
          </div>
        </div>
        <hr class="separator" />
        <!--<cja-input-group opened="true" title="bb.results.filter.avgMonthlyCost">
          <cja-slider
            keyName="avgMonthlyCost"
            min="0"
            max="100"
            step="10"
            default="100"
            unit="ccy"
            v-model="avgMonthlyCost"
          ></cja-slider>
        </cja-input-group>

        <cja-input-group opened="true" title="bb.results.filter.avgToggleTitle">
          <cja-toggle keyName="avgToggle" v-model="avgToggle"></cja-toggle>
        </cja-input-group>-->

        <cja-input-group opened="true" title="bb.results.filter.package">
          <cja-toggle keyName="addSim" v-model="addSim"></cja-toggle>
          <div v-if="addSim">
            <hr class="separator" />
            <cja-counter
              keyName="numOfSim"
              min="1"
              max="5"
              v-model="numOfSim"
            ></cja-counter>
            <hr class="separator" />
            <div class="pt-5">
              <span>{{ $t("bb.results.filter.mobileData") }}</span>
              <cja-slider
                formDataName="mobileData"
                keyname="mobileData"
                min="0"
                max="10"
                default="0"
                unit="gb"
                v-model="mobileData"
              ></cja-slider>
            </div>
          </div>
          <hr class="separator" />
          <cja-toggle
            keyName="addBroadbandLaptop"
            v-model="addBroadbandLaptop"
          ></cja-toggle>
          <div v-if="addBroadbandLaptop">
            <hr class="separator" />
            <cja-slider
              formaDataName="broadbandData"
              keyName="broadbandData"
              min="0"
              max="50"
              step="10"
              unit="gb"
              v-model="broadbandData"
            ></cja-slider>
          </div>
        </cja-input-group>

        <cja-input-group opened="true" title="bb.results.filter.downloadSpeed">
          <cja-slider
            keyName="downloadSpeed"
            min="0"
            max="1000"
            step="100"
            default="0"
            unit="mbpersec"
            v-model="downloadSpeed"
          ></cja-slider>
        </cja-input-group>
        <cja-input-group
          opened="true"
          hint="bb.results.filter.tvFeature.hint"
          title="widget.filter-panel.switches.tvFeature.title"
        >
          <cja-toggle keyName="hasBox" v-model="hasBox"></cja-toggle>
        </cja-input-group>

        <cja-input-group opened="true" title="bb.results.filter.providers">
          <cja-providers-box v-model="providers"></cja-providers-box>
        </cja-input-group>

        <cja-input-group
          opened="true"
          title="bb.results.filter.premiumPackages"
        >
          <cja-toggle-box
            keyName="premiumPackages"
            :options="premiumPackagesOptions"
            v-model="premiumPackages"
          >
          </cja-toggle-box>
        </cja-input-group>

        <cja-input-group
          opened="true"
          noSeparator="true"
          title="bb.results.filter.category"
        >
          <cja-radio
            keyName="category"
            :options="categoryOptions"
            v-model="category"
          ></cja-radio>
        </cja-input-group>
        <div class="d-block d-xl-none space"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import screenSizeDetector from "../../../mixins/screenSizeDetector.js";
import gtmEvents from "../../../mixins/gtm/gtmEvents";
import Slider from "./inputs/Slider.vue";
import Dropdown from "./inputs/Dropdown.vue";
import ToggleBox from "./inputs/ToggleBox.vue";
import ProvidersBox from "./inputs/ProvidersBox.vue";
import InputGroup from "./inputs/InputGroup.vue";
import Counter from "./inputs/Counter.vue";
import Toggle from "./inputs/Toggle.vue";
import Radio from "./inputs/Radio.vue";
import { mapGetters } from "vuex";
import { eventBus } from "../../../main";

export default {
  mounted() {
    if (this.isTablet) {
      this.toggleFilterPanel();
    }
  },
  computed: {
    ...mapGetters([
      "data",
      "filterOpen",
      "resetActions",
      "premiumPackagesOptions",
      "tvFeatureOptions",
      "mainInternetUsageOptions",
      "tvImportantOptions",
      "landlineOptions",
      "categoryOptions",
    ]),
    downloadSpeed: {
      get() {
        return eval(this.$store.getters.data.downloadSpeed);
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "downloadSpeed",
          value: val,
        });
      },
    },
    avgMonthlyCost: {
      get() {
        return eval(this.$store.getters.data.avgMonthlyCost);
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "avgMonthlyCost",
          value: val,
        });
      },
    },
    tvImportant: {
      get() {
        return this.$store.getters.data.tvImportant;
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "tvImportant",
          value: val,
        });
      },
    },
    landline: {
      get() {
        return this.$store.getters.data.landline;
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "landline",
          value: val,
        });
      },
    },
    premiumPackages: {
      get() {
        return this.$store.getters.data.premiumPackages;
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "premiumPackages",
          value: val,
        });
      },
    },
    mainInternetUsage: {
      get() {
        return this.$store.getters.data.mainInternetUsage;
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "mainInternetUsage",
          value: val,
        });
      },
    },
    hasBox: {
      get() {
        return eval(this.$store.getters.data.hasBox);
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "hasBox",
          value: val,
        });
      },
    },
    providers: {
      get() {
        return this.$store.getters.data.provider;
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "provider",
          value: val,
        });
      },
    },
    avgToggle: {
      get() {
        return eval(this.$store.getters.data.avgToggle);
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "avgToggle",
          value: val,
        });
      },
    },
    addSim: {
      get() {
        return eval(this.$store.getters.data.addSim);
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "addSim",
          value: val,
        });
      },
    },
    numOfSim: {
      get() {
        return eval(this.$store.getters.data.numOfSim);
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "numOfSim",
          value: val,
        });
      },
    },
    mobileData: {
      get() {
        return eval(this.$store.getters.data.mobileData);
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "mobileData",
          value: val,
        });
      },
    },
    addBroadbandLaptop: {
      get() {
        return eval(this.$store.getters.data.addBroadbandLaptop);
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "addBroadbandLaptop",
          value: val,
        });
      },
    },
    broadbandData: {
      get() {
        return eval(this.$store.getters.data.broadbandData);
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "broadbandData",
          value: val,
        });
      },
    },
    category: {
      get() {
        return this.$store.getters.data.category;
      },
      set(val) {
        this.$store.dispatch("updateData", {
          key: "category",
          value: val,
        });
      },
    },
  },
  mixins: [screenSizeDetector, gtmEvents],
  methods: {
    /**
     * Toggles the filter panel.
     */
    toggleFilterPanel() {
      this.$store.dispatch("toggleFilterOpen");
      if ((this.isTablet || this.isMobile) && this.filterOpen) {
        document.querySelector("body").style = "position: fixed";
      } else {
        document.querySelector("body").style = "position: relative";
      }
    },
    /**
     * Resets the filters to their default values.
     *
     * Dispatches action from the store to reset the values and
     * emits a filterReset event with the GTM Action 'productLoaded'.
     */
    resetFilters() {
      this.sendProductSearchReset(this.resetActions);
      this.$store.dispatch("resetData");
      document.getElementById("filter").scrollIntoView({ behavior: "smooth" });
      eventBus.$emit("filterReset", "productLoaded");
    },
    /**
     * Toggles the filter panel.
     */
    applyFilters() {
      this.toggleFilterPanel();
    },
  },
  components: {
    cjaSlider: Slider,
    cjaDropdown: Dropdown,
    cjaToggleBox: ToggleBox,
    cjaProvidersBox: ProvidersBox,
    cjaInputGroup: InputGroup,
    cjaCounter: Counter,
    cjaRadio: Radio,
    cjaToggle: Toggle,
  },
};
</script>

<style lang="scss" scoped>
$top-line-color: #cccac8;
$button-border: #cccac8;
$button-color: #979290;
$button-background-color: white;
$button-shadow-color: #f3f1ed;
$success-button-border: #cccac8;
$success-button-color: white;
$success-button-background-color: #77aa43;

$success-button-shadow-color: #51732e;
$grey: #cccbc8;
$blue: #056f9d;

hr.separator {
  margin: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition-duration: 0.2s;
  transition-property: transform;
  transition-timing-function: ease;
}

.slide-enter,
.slide-leave-active {
  transform: scaleX(0);

  * {
    opacity: 0;
  }
}

.cja-filter-panel {
  background-color: white;
  width: 257px;
  transition: width 0.2s;

  &.open {
    width: 257px;
  }

  &.closed {
    width: 80px;
  }

  .modal-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    background-color: black;
    top: 0;
    left: 0;
    z-index: 9;
  }

  .header {
    padding: 2rem;
    display: table;
    width: 100%;
    height: 70px;
    max-height: 70px;
    cursor: pointer;

    .title {
      font-size: 2.7rem;
      line-height: 2.7rem;
    }

    .icon {
      display: table-cell;
      color: $grey;
      text-align: right;
      font-weight: 600;
    }
  }

  .content {
    position: absolute;
    top: 70px;
    background-color: white;
    width: 257px;
    max-width: 257px;
    transform-origin: left;
    -webkit-box-shadow: 0px 4px 20px 13px rgba(0, 0, 0, 0.05);

    -moz-box-shadow: 0px 4px 20px 13px rgba(0, 0, 0, 0.05);

    box-shadow: 0px 4px 20px 13px rgba(0, 0, 0, 0.05);

    .content-header {
      background-color: $blue;
      color: white;
      padding: 1rem;

      span {
        font-size: 2.5rem;
        padding: 1rem;

        em {
          cursor: pointer;
        }
      }
    }

    &.tablet {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      overflow: scroll;
      z-index: 10;
    }

    &.open.mobile {
      width: 100%;
      max-width: 100%;
      overflow-x: hidden;
    }
  }

  .showAll {
    padding: 0 15px;

    .btn {
      width: 100%;
      padding: 1rem;
      background-color: $button-background-color;
      border: solid $button-border 1px;
      color: $button-color;
      font-size: 2rem;
      font-weight: 600;
      webkit-box-shadow: inset 0 -5px 0 0 $button-shadow-color;
      -moz-box-shadow: inset 0 -5px 0 0 $button-shadow-color;
      box-shadow: inset 0 -5px 0 0 $button-shadow-color;

      &:hover {
        background-color: darken($button-background-color, 5%);
        -webkit-box-shadow: inset 0 -5px 0 0 darken($button-shadow-color, 5%);
        -moz-box-shadow: inset 0 -5px 0 0 darken($button-shadow-color, 5%);
        box-shadow: inset 0 -5px 0 0 darken($button-shadow-color, 5%);
      }

      &:active {
        background-color: $button-background-color;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-color: $button-color;
      }
    }
  }

  .applyFilter {
    padding: 0 15px;

    .btn {
      width: 100%;
      padding: 1rem;
      background-color: $success-button-background-color;
      border: solid $success-button-border 1px;
      color: $success-button-color;
      font-size: 2rem;
      font-weight: 600;
      webkit-box-shadow: inset 0 -5px 0 0 $success-button-shadow-color;
      -moz-box-shadow: inset 0 -5px 0 0 $success-button-shadow-color;
      box-shadow: inset 0 -5px 0 0 $success-button-shadow-color;

      &:hover {
        background-color: darken($success-button-background-color, 5%);
        -webkit-box-shadow: inset 0 -5px 0 0 darken($success-button-shadow-color, 5%);
        -moz-box-shadow: inset 0 -5px 0 0 darken($success-button-shadow-color, 5%);
        box-shadow: inset 0 -5px 0 0 darken($success-button-shadow-color, 5%);
      }

      &:active {
        background-color: $success-button-background-color;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-color: $success-button-color;
      }
    }
  }
}
</style>
