<template>
  <div class="modal fade" id="leadCapture" role="dialog" aria-labelledby="leadCaptureLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="container">
            <div class="row">
              <div class="col-10 offset-1">
                <h1 class="modal-title" id="leadCaptureLabel">
                  Pedido de Contacto
                </h1>
              </div>
              <div class="col-1">
                <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                  <span aria-hidden="true" class="m-cgg-icon--ciab-cross"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!thankYou" class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-6 left">
                <div class="row mt-5">
                  <div class="col-12 powered">
                    Powered by
                    <br>
                    <img class="provider-image cja-logo" :src="
                      imageBaseURL +
                      '/s3/portugal/comparaja.pt/production/pt/images/general/backoffice-navbar-logo.png'
                    " :alt="productName"/>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12">
                    <div class="jumbotron">
                      <div class="provider-promise">
                        <span>Iremos entrar em<br/>contacto consigo</span>
                      </div>
                      <div class="provider-explanation mt-3">
                        <span>Ao submeter, iremos entrar em contato consigo para apresentar uma proposta de acordo com os seus interesses.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 right">
                <form class="col-12 mt-5 form-lead-capture">
                  <div class="form-group row">
                    <input id="input-lead-capture" type="text" name="firstName" v-model="firstName"
                           @blur="$v.firstName.$touch()" :placeholder="
                        $t('widget.results.lead-capture-form.name-placeholder')
                      " class="form-control form-control-lg col-12" :class="
  $v.firstName.$dirty && $v.firstName.$invalid
    ? 'invalid'
    : ''
"/>
                    <small v-if="
                      $v.firstName.$dirty &&
                      $v.firstName.$invalid &&
                      !$v.firstName.required
                    " class="form-text text-danger">{{
                        $t("widget.lead-capture-form.name-error-message")
                      }}</small>
                    <small v-else-if="
                      $v.firstName.$dirty &&
                      $v.firstName.$invalid &&
                      !$v.firstName.name
                    " class="form-text text-danger">{{
                        $t("widget.lead-capture-form.name-format-error-message")
                      }}</small>
                  </div>
                  <div class="form-group row">
                    <input id="input-lead-capture" type="text" name="email" v-model="email" @blur="$v.email.$touch()"
                           :placeholder="
                        $t('widget.results.lead-capture-form.email-placeholder')
                      " class="form-control form-control-lg col-12" :class="
  $v.email.$dirty && $v.email.$invalid ? 'invalid' : ''
"/>
                    <small v-if="
                      $v.email.$dirty &&
                      $v.email.$invalid &&
                      !$v.email.required
                    " class="form-text text-danger">{{ $t("widget.email-field.email-required") }}</small>
                    <small v-else-if="
                      $v.email.$dirty &&
                      $v.email.$invalid &&
                      $v.email.required
                    " class="form-text text-danger">{{ $t("widget.email-field.wrong-format") }}</small>
                  </div>
                  <div class="form-group row">
                    <input type="text" value="+351" class="form-control form-control-lg col-4" readonly/>
                    <input id="input-lead-capture" type="text" v-number inputmode="numeric" maxlength="9" name="phone"
                           v-model="phone" @blur="$v.phone.$touch()" :placeholder="
                        $t('widget.results.lead-capture-form.phone-placeholder')
                      " class="form-control form-control-lg col-8" :class="
  $v.phone.$dirty && $v.phone.$invalid ? 'invalid' : ''
"/>
                    <small v-if="
                      $v.phone.$dirty &&
                      $v.phone.$invalid &&
                      !$v.phone.required
                    " class="form-text text-danger">{{
                        $t("widget.lead-capture-form.phone-error-message")
                      }}</small>
                    <small v-else-if="
                      $v.phone.$dirty &&
                      $v.phone.$invalid &&
                      (!$v.phone.numeric ||
                        !$v.phone.minLentgh ||
                        !$v.phone.maxLength ||
                        !$v.phone.phone)
                    " class="form-text text-danger">{{
                        $t(
                            "widget.lead-capture-form.phone-format-error-message"
                        )
                      }}</small>
                  </div>
                </form>
                <div class="row">
                  <div class="col-12">
                    <input type="checkbox" id="input-lead-capture" v-model="checkTC"> &nbsp;
                    <span class="checkbox-text">
                      Concordo com os  <a href="https://www.comparaja.pt/termos-condicoes-gerais" target='_blank'>Termos e Condições</a>
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <input type="checkbox" id="input-lead-capture" v-model="checkPP"> &nbsp;
                    <span class="checkbox-text">
                      Concordo com a <a href="https://www.comparaja.pt/politica-privacidade/deco" target='_blank'>Política de Privacidade</a>
                    </span>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-12">
                    <a class="cgg-button-cta btn btn-block action-button"
                       :class="$v.$invalid || !checkTC || !checkPP ? 'disabled' : ''"
                       @click="submit" role="button">{{ $t("widget.button.submit") }}
                      <em class="m-cgg-icon--chevron-right-circle"></em></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="thankYou" class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-12 left">
                <div class="row thank-you-image">
                  <div class="col-12 powered">
                    Powered by
                    <br>
                    <img class="provider-image cja-logo" :src="
                      imageBaseURL +
                      '/s3/portugal/comparaja.pt/production/pt/images/general/backoffice-navbar-logo.png'
                    " :alt="productName"/>
                  </div>
                </div>
              </div>

              <div class="col-12 right thank-you">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12">
                        <h1>Muito obrigado</h1>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <h3>Iremos entrar em contacto consigo em breve.</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <a class="btn btn-block close-button" data-toggle="modal" data-target="#leadCapture"
                           role="button">{{ $t("widget.lead-capture-form.close") }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!thankYou" class="modal-footer">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <span><em class="m-cgg-icon--lock"></em> {{ $t(privacy) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {eventBus} from "../../main";
import {email, maxLength, minLength, numeric, required,} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      product: {},
      firstName: "",
      lastName: "",
      fullName: "",
      email: "",
      phone: "",
      imageBaseURL: process.env.VUE_APP_CJA_URL,
      checkTC: false,
      checkPP: false
    };
  },
  created() {
    eventBus.$on("setLeadCapture", (product) => {
      this.product = product;
      this.$v.$reset();
      if (this.name && this.phone && !this.thankYou) {
        this.submit();
      }
    });
  },
  computed: {
    ...mapGetters(["data", "leadCapturePopupEndpoint", "thankYou"]),
    privacy() {
      var p = "";
      if (this.product && this.product.name) {
        p = "_" + this.product.name;
      }
      return "widget.lead-capture-form.privacy" + p;
    },
    promise() {
      var p = "";
      if (this.product && this.product.name != undefined) {
        p = "_" + this.product.name;
      }
      return "widget.lead-capture-form.promise.broadband" + p;
    },
    explanation() {
      var p = "";
      if (this.product && this.product.name) {
        p = "_" + this.product.name;
      }
      return "widget.lead-capture-form.explanation" + p;
    },
    image() {
      if (this.product && this.product.provider) {
        return this.product.provider.image || this.product.image || "";
      }
      return "";
    },
    thankYouTitle() {
      var p = "";
      if (this.product && this.product.name) {
        p = "_" + this.product.name;
      }
      return "widget.lead-capture-form.thank-you-title-standard" + p;
    },
    thankYouText() {
      var p = "";
      if (this.product && this.product.name) {
        p = "_" + this.product.name;
      }
      return "widget.lead-capture-form.thank-you-text" + p;
    },
    productName() {
      if (this.product && this.product.provider) {
        return this.product.provider.name || this.product.name || "";
      }
      return "";
    },
    name() {
      return this.fullName != ""
          ? this.fullName
          : this.firstName + " " + this.lastName;
    },
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (!this.$v.$invalid && this.checkTC && this.checkPP) {
        let request = {
          cggId: this.product.cggId,
          data: {
            email: this.email,
            phone: this.phone,
            name: this.name,
            firstName: this.firstName || this.name,
            terms: true,
            privacy: true,
            tc_consent: true,
            dm_Consent: true,
            provider: this.product.provider.cggId,
            isCompleted: true,
            eventType: "RESULTS"
          },
          journeyId: this.data.journeyId,
          viewId: this.product.viewId,
        };
        request.data = JSON.stringify(request.data);
        this.$http.put(
            process.env.VUE_APP_CJA_URL + this.leadCapturePopupEndpoint,
            request
        );
        let postObject = JSON.stringify({
          event: 'iFrameEvent',
          param_0: 'SSEL-Telco',
          param_1: 'ComparaJa',
          param_2: 'Ask for contact'
        });
        parent.postMessage(postObject, 'https://www.deco.proteste.pt');
        this.$store.dispatch(
            "setThankYou",true);
      }
    },
  },
  validations: {
    firstName: {
      required,
      name(name) {
        return /^[a-zA-Z \u00C0-\u00FF]{1,30}$/.test(name);
      },
    },
    email: {
      required,
      email,
    },
    phone: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(9),
      numeric,
      phone(phone) {
        return /^[2|3|9][0-9]{8}/.test(phone);
      },
    },
    form: ["firstName", "email", "phone"],
  },
};
</script>

<style lang="scss" scoped>
$orange: #f68835;
$dark-orange: #e45f2c;
$grey: #f3f1ed;
$dark-grey: #cccbc8;
$header-bg-color: #519bc6;
$header-color: white;
$text-color: #979290;
$jumbotron-color: #95918f;
$jumbotron-bg-color: #f3f2ee;
$input-border-green: #77aa43;
$input-border-red: #ff533f;

.modal {
  overflow-y: scroll;

  .modal-header {
    background-color: $header-bg-color;
    color: $header-color;

    .modal-title {
      font-size: 3em;
      text-align: center;
    }

    .close {
      font-size: 2.5rem;
      padding: 2rem;

      span {
        color: white;
      }
    }
  }

  .modal-body {
    text-align: center;

    .left {
      .thank-you-image {
        display: inline-grid;
        align-items: center;
        margin-block: 20px;
      }

      .jumbotron {
        padding: 2rem;
        background-color: $jumbotron-bg-color;
        color: $jumbotron-color;

        .provider-promise {
          font-size: 2.75rem;
        }

        .provider-explanation {
          text-align: center;
          font-size: 2rem;
        }
      }
    }

    .right {
      text-align: left;

      &.thank-you {
        color: $text-color;
        text-align: center;

        .row {
          padding: 1rem;
        }
      }

      .form-lead-capture {
        .form-group {
          .form-control {
            &:focus {
              border: 1px solid $input-border-green;
              outline: 1px solid $input-border-green;
              box-shadow: none;
            }

            &[readonly] {
              background-color: inherit;
            }

            &.form-control-lg {
              font-size: 2rem;
              height: 6rem;
              padding: 1rem;
            }

            &.invalid {
              border: 1px solid $input-border-red;
              box-shadow: none;

              &:focus {
                outline: 1px solid $input-border-red;
              }
            }
          }

          .form-text {
            font-size: 1.5rem;
          }
        }
      }

      .checkbox-text {
        color: $text-color;
        font-size: 1.5rem;
        text-align: left;
      }

      .action-button {
        padding: 1.5rem;
        font-size: 2.25rem;
        width: 100%;
        font-weight: 700;
        background-color: #f68835;
        border-radius: 3px;
        color: white;
        text-decoration: none;
        display: block;

        &.disabled {
          opacity: 0.5;
          pointer-events: all;

          &:hover {
            cursor: not-allowed;
          }
        }

        &:hover {
          background-color: #f68835;
          border-color: #ff533f;
        }

        em {
          top: 0;
        }
      }

      .close-button {
        padding: 1.5rem;
        font-size: 2.25rem;
        width: 100%;
        font-weight: 700;
        background-color: white;
        border: 1px solid $dark-grey;
        box-shadow: inset 0 -5px 0 0 $grey;
        border-radius: 3px;
        color: $text-color;
        text-decoration: none;
        display: block;

        &:hover {
          background-color: darken(white, 5%);
          box-shadow: inset 0 -5px 0 0 darken($grey, 5%);
        }
      }
    }
  }

  .modal-footer {
    font-size: 1.7rem;
    color: $text-color;
    border-top: 0;
  }

  .cja-logo {
    height: 22px;
  }

  .powered {
    font-size: 12px;
  }
}

.modal-dialog-centered {
    align-items: flex-start;
}


.modal-content {
    margin-top: 200px;
}
</style>
