<template>
  <div id="app">
    <div class="cja-results">
      <div class="header-wrapper">
        <div class="filter-wrapper">
          <a
            v-if="!filterOpen"
            class="btn btn-filter"
            @click="toggleFilterOpen"
            role="button"
            ><em class="m-cgg-icon--filter-panel"></em>
            {{ $t("widget.filter-panel.title") }}</a
          >
          <a
            v-else
            class="btn btn-apply-filter"
            @click="toggleFilterOpen"
            role="button"
          >
            {{ $t("cc.results.filter.showResults") }}</a
          >
        </div>
        <cja-filter></cja-filter>
      </div>
      <div
        id="results"
        class="results"
        :class="filterOpen && !isTablet ? 'narrow' : ''"
      >
        <div v-if="isLoading" class="loading">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <p>{{ $t("widget.spinner.text") }}</p>
        </div>
        <div v-else>
          <div>
            <cja-result
              v-for="(product, index) in paginatedProducts"
              :product="product"
              :index="index"
              :key="product ? product.cggId : random()"
            ></cja-result>
            <!--  previous button -->
            <button
              class="pages-button"
              @click="currentPage--, scrollToTop()"
              :disabled="currentPage === 1"
            >
              <em class="m-cgg-icon--arrow-left"></em>
            </button>
            <!--  pages by number -->
            <span v-for="index in totalPages" v-bind:key="index">
              <span
                class="page-label"
                @click="(currentPage = index), scrollToTop()"
                :class="{ 'current-label': currentPage === index }"
                >{{ index }}</span
              >
              &nbsp;
            </span>

            <!-- next button -->
            <button
              class="pages-button"
              @click="currentPage++, scrollToTop()"
              :disabled="currentPage === totalPages"
            >
              <em class="m-cgg-icon--arrow-right"></em>
            </button>
          </div>
        </div>
      </div>
    </div>
    <cja-lead-capture
      v-if="
        (productList && productList.length > 0) ||
        (bestOffersList && bestOffersList.length > 0)
      "
    >
    </cja-lead-capture>
    <cja-checkout ref="checkoutModal"></cja-checkout>
    <div class="d-block d-xl-none space"></div>
    <div class="row d-block d-xl-none fixed-bottom">
      <div class="col-12 filter-wrapper">
        <a
          v-if="!filterOpen"
          class="btn btn-filter"
          @click="toggleFilterOpen"
          role="button"
          ><em class="m-cgg-icon--filter-panel"></em>
          {{ $t("widget.filter-panel.title") }}</a
        >
        <a
          v-if="filterOpen"
          class="btn btn-apply-filter"
          @click="toggleFilterOpen"
          role="button"
        >
          {{ $t("bb.results.filter.showResults") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Filter from "./components/BBNew/Filter.vue";
import Result from "./components/BBNew/Result.vue";
import LeadCapture from "./components/result/LeadCapture.vue";
import Checkout from "./components/BBNew/Checkout.vue";
import AbTest from "./components/abTest/abTest.vue";
import screenSizeDetector from "./mixins/screenSizeDetector";
import gtmEvents from "./mixins/gtm/gtmEventsBBNew.js";
import { mapGetters } from "vuex";
import { eventBus } from "./main";

export default {
  components: {
    cjaFilter: Filter,
    cjaResult: Result,
    cjaLeadCapture: LeadCapture,
    cjaAbTest: AbTest,
    cjaCheckout: Checkout,
  },
  data() {
    return {
      results: {},
      isLoading: false,
      loadingResults: false,
      call: undefined,
      currentPage: 1,
      itemsPerPage: 6,
    };
  },
  mixins: [screenSizeDetector, gtmEvents],
  async created() {
    /**
     * Listens to updates in the filters to fetch the products.
     */
    eventBus.$on(
      ["sortUpdated", "categoryUpdated", "filterReset", "forcedChange"],
      (gtmEventName) => {
        this.getProducts(gtmEventName);
        this.changePath();
      }
    );

    eventBus.$on("openCheckout", (data) => {
      this.$refs.checkoutModal.show(data.product, data.index);
    });

    const journeyId = await this.$http
      .post(
        process.env.VUE_APP_API_URL + "/partner-api/v1/lead/create",
        {
          name: "",
          phone: "",
          email: "",
          verticalCode: "BB",
          partnerId: "Deco",
          fromPartner: "Deco",
        },
        {
          headers: {
            "x-auth-apikey": process.env.VUE_APP_API_KEY,
            "x-auth-username": process.env.VUE_APP_USERNAME,
            "x-auth-password": process.env.VUE_APP_PASSWORD,
          },
        }
      )
      .then(({ data }) => {
        return data.id;
      });

    /**
     * Fetches the queryString from the URL and initializes the App values in the store.
     */
    this.$store.dispatch("resetData");
    this.$store.dispatch(
      "setDataFromQuery",
      this.$router.history.current.query
    );
    this.$store.dispatch("updateData", {
      key: "bestOffersShow",
      value: false,
    });
    this.$store.dispatch("updateData", {
      key: "journeyId",
      value: journeyId,
    });

    this.$store.dispatch("setName", "");
    this.$store.dispatch("setPhone", "");
    /**
     * Listens to updates in the filters to fetch the products.
     */
    var getProd = this.getProducts;
    var changeURL = this.changePath;
    window.addEventListener("change", function (event) {
      if (!event.target.id.includes("input-lead-capture")) {
        getProd("productLoaded");
        changeURL();
      }
    });
    this.getProducts();
  },
  computed: {
    ...mapGetters([
      "data",
      "queryString",
      "products",
      "productsEndpoint",
      "filterOpen",
      "showAllProducts",
    ]),
    paginatedProducts() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.bestOffersList.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.bestOffersList.length / this.itemsPerPage);
    },
    productList() {
      var nonfeatured = [];
      if (
        this.products.products &&
        (this.showAllProducts || !this.data.bestOffersShow)
      ) {
        nonfeatured = this.products.products;
      }

      if (this.products.featuredProducts) {
        nonfeatured.concat(this.products.featuredProducts);
      }
      if (this.products.exclusiveProducts) {
        nonfeatured.concat(this.products.exclusiveProducts);
      }
      return nonfeatured.sort(
        (o1, o2) => o1.fee.monthlyCost - o2.fee.monthlyCost
      );
    },

    // eslint-disable-next-line vue/return-in-computed-property
    bestOffersList() {
      var featured = [];
      var exclusive = [];
      var bestOffer = [];
      var features = [];
      var availableAtAddress = -1;

      if (this.products.featuredProducts) {
        featured = this.products.featuredProducts;
        if (
          featured !== "undefined" &&
          featured.length > 0 &&
          featured.find(
            (element) => element.offer.isAvailableAtAddress === "1"
          ) !== undefined
        ) {
          availableAtAddress = 1;
        }
      }

      var nonfeatured = [];
      if (
        this.products.products &&
        (this.showAllProducts || !this.data.bestOffersShow)
      ) {
        nonfeatured = this.products.products;
      }

      if (this.products.featuredProducts) {
        nonfeatured.concat(this.products.featuredProducts);
      }

      if (this.products.exclusiveProducts) {
        nonfeatured.concat(this.products.exclusiveProducts);
      }

      if (this.products.exclusiveProducts) {
        if (this.products.exclusiveProducts != undefined) {
          this.products.exclusiveProducts.forEach((index) => {
            index.offer.isAvailableAtAddress = availableAtAddress;
          });
        }
        exclusive = this.products.exclusiveProducts;
      }

      if (this.products.bestOfferProducts) {
        bestOffer = this.products.bestOfferProducts;
      }

      featured.concat(bestOffer);

      if (this.products.featuredProducts) {
        if (this.products.featuredProducts != undefined) {
          this.products.featuredProducts.forEach((index) => {
            index.offer.isAvailableAtAddress = availableAtAddress;
          });
        }
        features = this.products.featuredProducts;
      }
      featured.concat(features);
      featured = featured
        .concat(nonfeatured)
        .sort((p1, p2) =>
          p1.fee.avgMonthlyCost > p2.fee.avgMonthlyCost
            ? 1
            : p1.fee.avgMonthlyCost < p2.fee.avgMonthlyCost
            ? -1
            : 0
        );
      return featured;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    /**
     * Pushes the current filters values to the URL.
     */
    changePath() {
      window.history.pushState(
        this.data,
        "",
        window.location.pathname + "?" + this.queryString
      );
    },
    /**
     * Fetches the next page of results from CIAB.
     *
     * Updates the products in the store and
     * launches the GTM Event Show More.
     */
    showAllProductsList() {
      this.$store.dispatch("toggleShowAllProduct", true);
    },
    getNextPage() {
      this.loadingResults = true;
      var page = this.data.page;
      this.$store.dispatch("updateData", {
        key: "page",
        value: eval(page) + 1,
      });
      var t0 = performance.now();
      this.$http
        .get(
          process.env.VUE_APP_CJA_URL +
            this.productsEndpoint +
            "?" +
            this.queryString
        )
        .then((result) => {
          var t1 = performance.now();
          var timeTaken = Math.round(t1 - t0);
          this.loadingResults = false;
          this.$store.dispatch("updateProducts", result.data);
          this.sendGTMSimpleEvent(
            "productShowMore",
            "show more",
            timeTaken,
            this.productList
          );
        });
    },
    /**
     * Fetches the products from the CIAB given the current filters.
     *
     * Launches a GTM Event, cancels the previous request if another is made.
     *
     * @param {String} gtmEventName The GTM Event name that launches the request.
     * @param {String} gtmEvenAction The GTM Event action to launch the GTM Event.
     */
    getProducts(gtmEventName, gtmEventAction) {
      this.$store.dispatch("updateData", { key: "page", value: 1 });
      if (this.call) {
        this.call.cancel("Request canceled by another request.");
      }
      this.call = this.$http.CancelToken.source();
      this.isLoading = true;
      var t0 = performance.now();
      this.$http
        .get(
          process.env.VUE_APP_CJA_URL +
            this.productsEndpoint +
            "?" +
            this.queryString,
          { cancelToken: this.call.token }
        )
        .then((result) => {
          var t1 = performance.now();
          var timeTaken = Math.round(t1 - t0);
          this.isLoading = false;
          this.$store.dispatch("setProducts", result.data);
          if (!gtmEventName) {
            gtmEventName = "impressions";
            gtmEventAction = "results impressions";
          }
          this.sendGTMSimpleEvent(
            gtmEventName,
            gtmEventAction,
            timeTaken,
            this.bestOffersList
          );
          return Promise.resolve(result);
        })
        .catch((thrown) => {
          if (this.$http.isCancel(thrown)) {
            return Promise.resolve(new Error(thrown.message));
          } else {
            this.isLoading = false;
            this.$store.dispatch("setProducts", []);
            return Promise.reject(thrown);
          }
        });
    },
    /**
     * Toggles the filter panel.
     */
    toggleFilterOpen() {
      this.$store.dispatch("toggleFilterOpen");
      if ((this.isTablet || this.isMobile) && this.filterOpen) {
        document.querySelector("body").style = "position: fixed";
      } else {
        document.querySelector("body").style = "position: relative";
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/colors.sass";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

$background-color: #fff;
$loading-color: #96918f;
$button-color: #979290;
$a-green: #77aa43;
$blue1: #076b9c;

.space {
  height: 10rem;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  text-align: center;
  background-color: #fafafa;
  padding: 3rem;
  padding-top: 0;

  .btn-filter {
    background-color: white;
    border-radius: 3px;
    padding: 1.5rem 4rem;
    font-size: 2rem;
    border: 1px solid $grey2;
  }

  .btn-apply-filter {
    background-color: #f68835;
    border-radius: 3px;
    padding: 1.5rem 4rem;
    font-size: 2rem;
    border: 1px solid $orange2;
    color: white !important;
  }
}

.back-to-top {
  position: fixed;
  right: 5rem;
  bottom: 5rem;
  background-color: transparent;
  width: 5rem;
  height: 5rem;
  color: $grey3;
  border-color: $grey3;
  border: 0.2rem solid $grey3;
  border-radius: 5rem;

  &:focus {
    outline: none;
  }

  em {
    font-size: 2rem;
  }
}

.checkbox-text {
  a {
    color: $a-green;
  }
}

.popover {
  font-size: 1.5rem;

  .arrow {
    border: 0;
  }
}

.row:before,
.row:after {
  display: none !important;
}

html {
  font-size: 10px;

  @media (max-width: 500px) {
    font-size: 8px;
  }

  @media (max-width: 380px) {
    font-size: 7px;
  }
}

body {
  overflow: visible;
  background-color: $background-color;
  font-family: Nunito Sans, Helvetica, sans-serif;

  .cja-results {
    font-size: 1.5rem;

    .results {
      width: 1125px;
      max-width: 1125px;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      padding-left: 0;
      -webkit-transition: padding-left 0.2s;
      -moz-transition: padding-left 0.2s;
      -o-transition: padding-left 0.2s;
      transition: padding-left 0.2s;

      @media (max-width: 1200px) {
        width: 100%;
      }

      &.narrow {
        padding-left: 272px;

        @media (max-width: 1200px) {
          padding-left: 365px;
        }
      }

      .all-results {
        margin: auto;
        width: 70%;
        border-top: 1px solid #dedede;
        font-size: 18px;
        padding-bottom: 2rem;

        @media (max-width: 658px) {
          font-size: 16px;
        }

        @media (max-width: 321px) {
          font-size: 15px;
        }
      }

      .all-results-top {
        margin: auto;
        width: 50%;
        border-top: 1px solid #dedede;
      }

      .no-results {
        margin-top: 2rem;
        margin-bottom: 2rem;
        color: $loading-color;
        font-size: 2rem;
      }

      .all-results-text {
        margin-top: 2rem;
        margin-bottom: 2rem;
        color: $loading-color;
        font-size: 18px;
        font-weight: 600;

        @media (max-width: 658px) {
          font-size: 16px;
        }

        @media (max-width: 321px) {
          font-size: 15px;
        }
      }

      .loading {
        padding-top: 2rem;

        .spinner-border {
          height: 10rem;
          width: 10rem;
          padding: 2rem;
          border-width: 0.725rem;
          color: $loading-color;
        }

        p {
          margin-top: 2rem;
          margin-bottom: 2rem;
          color: $loading-color;
          font-size: 2rem;
        }
      }

      .show-more-results {
        padding: 1rem;

        .loading {
          padding-top: 0;
        }

        .seeall-color {
          color: #076b9c;
          font-size: 16px;
          transform: rotate(90deg);
          display: inline-block;
          margin-left: 1rem;
          cursor: pointer;

          @media (max-width: 658px) {
            font-size: 16px;
          }

          @media (max-width: 321px) {
            font-size: 15px;
          }
        }

        .seeall-results {
          color: #076b9c;
          font-weight: 600;
          cursor: pointer;
          font-size: 20px;

          @media (max-width: 658px) {
            font-size: 18px;
          }

          @media (max-width: 321px) {
            font-size: 18px;
          }
        }

        .btn-show-more {
          color: #076b9c;
          border: 2px solid #076b9c;
          box-sizing: border-box;
          border-radius: 3px;
          padding: 1.5rem 3rem;
          background: white;
          font-weight: bold;
          font-size: 1.8rem;
        }

        .btn-show-more:hover {
          color: white;
          background: #076b9c;

          .spinner-border {
            border-width: 0.25rem;
          }

          &.disabled {
            cursor: not-allowed;
            pointer-events: none;
          }

          &:hover {
            background-color: #076b9c;
          }

          &:active {
            background-color: white;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            border-color: $button-color;
          }
        }
      }
    }
  }
}

.header-wrapper {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 49;
}

.filter-wrapper {
  background-color: #fff;
  padding: 5px 10px;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 1200px) {
    display: none;
  }

  .btn-filter,
  .btn-apply-filter {
    background-color: white;
    color: #212529 !important;
    border-radius: 3px;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid $grey2;
  }
}

.pages-button {
  padding: 10px;
  width: 44px;
  background: #ffffff;
  border: 1px solid #076b9c;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin: 32px;
}

.pages-button:hover {
  background: #f4f9fc;
  border: 1px solid #155072;
}

.pages-button:disabled {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 8px;
}

.pages-button + .pages-button {
  margin-left: 10px;
}

.page-label {
  text-decoration: underline;
  cursor: pointer;
}

.current-label {
  color: #96918f;
}
</style>
