<template>
  <div>
    <div id="filter" class="cja-filter">
      <div class="partnership">
        Simulador powered by &nbsp;
        <img
          :src="
            imageBaseURL +
            '/s3/portugal/comparaja.pt/production/pt/images/general/darkmode-backoffice-navbar-logo.png'
          "
          alt="ComparaJá.pt"
        />
      </div>

      <div class="cja-filter-wrapper">
        <div :class="isTablet ? 'container-fluid' : 'container'">
          <div class="row">
            <div
              class="d-fixed d-xl-block"
              :class="filterOpen ? 'col-xl-3' : 'float'"
            >
              <cja-filter-panel></cja-filter-panel>
            </div>
            <div
              class="col-md-12 np"
              :class="filterOpen ? 'col-xl-9 pl-xl-0' : 'col-xl-12'"
            >
              <cja-sort-menu></cja-sort-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSticky" class="cja-filter sticky">
      <div :class="isTablet ? 'container-fluid' : 'container'">
        <div class="row">
          <div :class="filterOpen ? 'col-9 offset-3 pl-0' : 'col-12 offset-3'">
            <cja-sort-menu :sticky="true"></cja-sort-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SortMenu from "../filter/SortMenu.vue";
import FilterPanel from "./filter/FilterPanel.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      hideTopSortMenu: false,
      isSticky: false,
      isTablet: false,
      imageBaseURL: process.env.VUE_APP_CJA_URL,
    };
  },
  mounted() {
    /**
     * Creates an observer to detect if the sort bar is out of the top of the viewport to make it stick to the top.
     */
    const observer = new window.IntersectionObserver(
      (changes) => {
        this.isSticky =
          (!changes[0].isIntersecting || changes[0].intersectionRatio != 1) &&
          changes[0].boundingClientRect.y < 0;
      },
      { threshold: [0, 1] }
    );
    observer.observe(document.querySelector("#filter"));
  },
  components: {
    cjaSortMenu: SortMenu,
    cjaFilterPanel: FilterPanel,
  },
  computed: {
    ...mapGetters(["filterOpen"]),
  },
};
</script>

<style lang="scss" scoped>
$green: #77aa43;
$shadow: rgba(0, 0, 0, 0.2);

.cja-filter {
  background-color: $green;

  .partnership {
    text-align: center;
    display: flex;
    background-color: #076b9c;
    color: white;
    height: 48px;
    width: 100%;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    font-weight: Medium;
    font-size: 14px;

    img {
      height: 60%;
    }
  }

  .float {
    position: absolute;
    z-index: 1;
    margin-left: 15px;
    width: 80px;
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 1px 3px $shadow;
    background-color: $green;
  }
}
</style>
