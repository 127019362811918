export default {
  data() {
    return {
      windowWidth: undefined,
      windowWidthObject: undefined,
      windowWidthSizes: {
        sizeLG: {sizeText: 'LG', size: 4},
        sizeMD: {sizeText: 'MD', size: 3},
        sizeSM: {sizeText: 'SM', size: 2},
        sizeXS: {sizeText: 'XS', size: 1}
      }
    }
  },
  computed: {
    screenSize() {
      if (this.windowWidth >= 1200) {
        return this.windowWidthSizes.sizeLG
      } else if (this.windowWidth >= 992) {
        return this.windowWidthSizes.sizeMD
      } else if (this.windowWidth >= 768)  {
        return this.windowWidthSizes.sizeSM
      } else {
        return this.windowWidthSizes.sizeXS
      }
    },
    isMobile() {
      return this.windowWidth < 768 
    },
    isTablet() {
      return this.windowWidth < 992
    }
  },
  created() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
      this.windowWidthObject = this.screenSize
    },
    minSize(size) {
      let sizeObject = this.textToSize(size)
      if (sizeObject) {
        return sizeObject.size <= this.windowWidthObject.size
      }
      return null
    },
    maxSize(size) {
        let sizeObject = this.textToSize(size)
        if (sizeObject) {
          return sizeObject.size >= this.windowWidthObject.size
        }
        return null
    },
    textToSize(text) {
      if(text) {
        text = text.toUpperCase()
        switch(text) {
          case 'LG':
            return this.windowWidthSizes.sizeLG
          case 'MD':
            return this.windowWidthSizes.sizeMD
          case 'SM':
            return this.windowWidthSizes.sizeSM
          case 'XS':
            return this.windowWidthSizes.sizeXS
                
        }
      }
      return null
    }
  }
}
