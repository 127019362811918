<template>
  <div id="sort-menu" class="cja-sort-menu" :class="sticky ? 'sticky' : ''">
    <div class="row pr-4 ml-0">
      <div
        :key="item.name"
        v-for="(item, index) in columns"
        @click="updateObj(item.name, item.sortParam)"
        class="col col-md-2 p-3 px-5 p-md-4 px-lg-5 p-xl-4"
        :class="{
          'col-xl-1 offset-xl-1': index == 0 && !filterOpen && !sticky,
          noclick: !item.sortParam,
          active: item.name == activeColumn,
          'col-4': item.mobile,
          'd-none d-md-block': !item.mobile,
        }"
      >
        <span>{{
          $t(
            "widget.sort-menu."+item.name)
        }}</span>
        <div v-if="item.name" class="sort-direction">
          <span>
            <em
              v-if="item.direction === 'ASC'"
              class="m-icon m-cgg-icon--arrow-bottom"
            ></em>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { mapGetters } from "vuex";

export default {
  props: ["sticky"],
  computed: {
    ...mapGetters(["filterOpen", "data", "activeColumn", "sorting"]),
    columns() {
      return this.sorting.columns;
    },
    avgToggle() {
      return this.data.avgToggle;
    },
    sortBy() {
      return this.data.sortBy;
    },
  },
  methods: {
    /**
     * Updates the sorting list, given the name and direction of the sort.
     *
     * Dispatches the setActiveColumn action from the store with the name of the sorting option and
     * emits the sortUpdated event with a the GTM action 'productSort'.
     *
     * @param {String} name The name of the sorting option
     * @param {String} sort The sorting keyName of the option
     */
    updateObj(name, sort) {
      for (let i = 0; i < this.sorting.columns.length; i++) {
        if (this.sorting.columns[i].name === name) {
          this.sorting.columns[i].direction = "ASC";
          this.sortBy.unshift(sort + "+");
          this.$store.dispatch("setActiveColumn", name);
          eventBus.$emit("sortUpdated", "productSort");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$green: #77aa43;
$light-green: #aaca8a;
$dark-green: #56924b;

.cja-sort-menu {
  background-color: $green;
  color: #fff;
  height: 70px;
  max-height: 70px;
  overflow: hidden;
  transition: all 0.2s;

  .row {
    height: 70px;

    &:first-child {
      border-left: 1px solid $light-green;
    }
    .col {
      border-right: 1px solid $light-green;
      text-overflow: ellipsis;

      .sort-direction {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 18px;
        color: $light-green;
      }
      &:hover {
        background-color: $dark-green;
        cursor: pointer;

        .sort-direction {
          color: white;
        }
      }
      &.noclick {
        pointer-events: none;
      }
      &.active {
        background-color: $dark-green;

        .sort-direction {
          color: white;
        }
      }
    }
  }
}
</style>
