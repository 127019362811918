<template>
  <div class="radio-group">
    <div class="cja-radio" v-for="option in options" :key="option">
      <label class="radio" :class="{ 'selected': selectedValue == option }">
        <input type="radio" ref="switch" v-model="selectedValue" :value="option" @change="updateValue">
        <span></span>
      </label>
      <span class="label">{{ $t(label(option)) }}</span>
    </div>
  </div>
</template>


<script>
import gtmEvents from '../../../../mixins/gtm/gtmEventsBBNew'

export default {
  mixins: [gtmEvents],
  props: ["value", "options", "keyName"],
  watch: {
    selectedValue() {
      this.$emit("input", this.selectedValue)
    }
  },
  data() {
    return {
      selectedValue: this.value
    }
  },
  methods: {
    label(option) {
      return 'bb.results.filter.' + this.keyName + '.' + option
    },
    updateValue() {
      this.$emit("input", this.selectedValue)
      this.sendProductSearch(this.keyName, this.selectedValue)
    }
  },
  mounted() {
    this.selectedValue = this.value
  }
}
</script>


<style lang='scss' scoped>
@import "../../../../assets/css/colors.sass";

.radio-group {
  min-height: 110px;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .cja-radio {
    width: 100%;
    vertical-align: middle;
    padding-top: 1rem;
    display: flex;

    .radio {
      position: relative;
      display: inline-block;
      width: 2.5rem;
      min-width: 2.5rem;
      height: 2.5rem;
      margin: 0;
      margin-right: 1rem;
      background-color: transparent;
      border-radius: 2.5rem;
      border: solid 1px $grey5;

      &:hover {
        cursor: pointer;
      }

      &.selected {
        border-color: $green1;
        -moz-box-shadow: inset 0 0 0 0.7rem $green1;
        -webkit-box-shadow: inset 0 0 0 0.7rem $green1;
        box-shadow: inset 0 0 0 0.7rem $green1;
      }

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      span {
        color: white;
        line-height: 2.4rem;
      }
    }

    .label {
      word-break: break-word;
    }
  }
}

;
</style>