<template>
  <div class="container-fluid cja-input-group">
    <div class="row" @click="toggleOpen">
      <div class="col-12 title">
        <span v-if="!hint">
          {{ $t(title) }}
          <em
            :class="
              isOpen ? 'm-cgg-icon--chevron-up' : 'm-cgg-icon--chevron-down'
            "
          ></em
        ></span>
        <span v-if="hint">
          {{ $t(title) }}
          <em
            class="green"
            :class="hintIsOpen ? 'm-cgg-icon--close' : 'm-cgg-icon--i-tooltip'"
            @click="toggleHint"
          ></em
        ></span>
      </div>
    </div>
    <div v-if="!noSeparator" class="input-separator">
      <hr class="m-0" v-if="!isOpen" />
    </div>
    <transition name="slide">
      <div v-if="hintIsOpen" class="row pt-4">
        <div class="col-12">
          <div class="hint-text">
            {{ $t(hint) }}
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide">
      <div class="row" v-show="isOpen">
        <div class="col-12">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["title", "noSeparator", "hint", "opened"],
  data() {
    return {
      isOpen: this.opened || false,
      hintIsOpen: false,
    };
  },
  methods: {
    toggleOpen() {
      if (!this.hint) {
        this.isOpen = !this.isOpen;
      }
    },
    toggleHint() {
      this.hintIsOpen = !this.hintIsOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors.sass";
.cja-input-group {
  .title {
    background-color: $grey7;
    color: $grey2;
    padding-top: 2rem;
    padding-bottom: 2rem;

    span {
      display: flex;
      justify-content: space-between;
      gap: 5px;
      width: 100%;

      em {
        padding-top: 5px;

        &:hover {
          cursor: pointer;
        }
        &.green {
          color: $green1;
        }
      }
    }
  }
  .input-separator {
    background-color: $grey7;
  }
  .hint-text {
    background-color: $green10;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
  }
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
