<template>
  <div>
    <div class="cja-slider-set">
      <span class="value">{{ new Intl.NumberFormat('de-DE').format(Number(inputValue)) }} {{ unit ?
          $t('widget.locale.symbol.' + unit) : alterUnit
      }}</span>
      <div v-if="!$v.inputValue.minValue" role="alert">
        <span class="error">{{ $t('bb.results.filter.' + keyName + '.error.tooLow') }}</span>
      </div>
      <div v-if="!$v.inputValue.maxValue" role="alert">
        <span class="error">{{ $t('bb.results.filter.' + keyName + '.error.tooHigh') }}</span>
      </div>
      <input v-if="!disable" type="range" :min="min" :max="max" :step="step || 0" :value="inputValue"
        @input="updateDisplay" @change="updateValue" ref="rangeInput" />
      <span class="label" v-if="!disable">
        <small>{{ new Intl.NumberFormat('de-DE').format(Number(min)) }} {{ unit ? $t('widget.locale.symbol.' + unit) :
            alterUnit
        }}</small>
        <small class="right">{{ new Intl.NumberFormat('de-DE').format(Number(max)) }} {{ unit ?
            $t('widget.locale.symbol.' + unit) : alterUnit
        }}</small>
      </span>
    </div>
  </div>
</template>

<script>
import screenSizeDetector from "../../../../mixins/screenSizeDetector"
import gtmEvents from '../../../../mixins/gtm/gtmEventsBBNew'
import { maxValue, minValue, numeric } from "vuelidate/lib/validators"

export default {
  props: ["keyName", "min", "max", "step", "unit", "value", "default", "noHint", "alterUnit", "alterKeyName", "alterHint", "disable"],
  watch: {
    value: function () {
      this.inputValue = this.value
    }
  },
  data() {
    return {
      display: {
        errorMessage: {
          min: "O valor é demasiado baixo",
          max: "O valor é demasiado alto"
        }
      },
      inputValue: this.value ? this.value : this.default || 0
    }
  },
  validations() {
    return {
      inputValue: {
        numeric,
        minValue: minValue(this.min),
        maxValue: maxValue(this.max)
      }
    }
  },
  mixins: [screenSizeDetector, gtmEvents],
  methods: {
    /**
     * Updates the local values
     * 
     * Sets the component inner state value , to be read by
     * all of the components inputs, and validates said value.
     * 
     * @param {event} e The event in wich the function is called
     */
    updateLocalValue(e) {
      var val = e.target.value
      if (val.length > 1 && val[0] == '0' && !isNaN(Number(val))) {
        val = val.substr(1)
      }
      val = val.replace(/[^0-9]/g, '')
      this.inputValue = eval(val) ? val : 0
      this.$v.$touch()
    },
    /**
     * Updates the value on the store,
     * emits the input event to the parent and
     * launches the GTM Event Product Search.
     */
    updateValue() {
      if (!this.$v.$invalid) {
        this.$emit("input", this.inputValue)
        this.sendProductSearch(this.keyName, this.inputValue)
      }
    },
    /**
     * Updates the displayed value in the text inputs when the 
     * range input is changed.
     */
    updateDisplay() {
      var val = this.$refs.rangeInput.value
      this.inputValue = eval(val) ? val : 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors.sass";

.cja-slider-set {
  padding-top: 2rem;
  padding-bottom: 2rem;

  span.value {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: $grey3;
  }

  .error {
    color: $red1;
  }

  input[type="text"] {
    padding: 0.5rem 1rem;
    width: 100%;
    border: 1px solid $grey4;
    border-radius: 3px;

    &:focus {
      border-color: $green1;
      outline-color: $green1;
    }
  }

  input[type=range] {
    -webkit-appearance: none;
    background-color: transparent;
    margin: 0;
    width: 100%;
    height: 40px;
    padding: 0;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 2rem;
      cursor: pointer;
      animate: 0.2s;
      box-shadow: none;
      background: $grey6;
      border-radius: 1rem;
      border: 0.2px solid $grey6;
    }

    &::-webkit-slider-thumb {
      box-shadow: none;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      background: $green1;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -0.25rem;
    }

    &::-moz-range-track {
      width: 100%;
      height: 2rem;
      cursor: pointer;
      animate: 0.2s;
      box-shadow: none;
      background: $grey6;
      border-radius: 1rem;
      border: 0.2px solid $grey6;
    }

    &::-moz-range-progress {
      width: 100%;
      height: 2rem;
      cursor: pointer;
      animate: 0.2s;
      box-shadow: none;
      background: $green10;
      border-radius: 1rem;
      border: 0.2px solid $green10;
    }

    &::-moz-range-thumb {
      box-shadow: none;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      background: $green1;
      cursor: pointer;
    }

    &::-moz-focus-outer {
      border: 0;
    }

    &::-ms-track {
      width: 100%;
      height: 2rem;
      cursor: pointer;
      animate: 0.2s;
      background: transparent;
      border-color: transparent;
      border-width: 12px 0;
      border-radius: 1rem;
      color: transparent;
      padding: 0;
      margin: 0;
    }

    &::-ms-fill-lower {
      background: $green10;
      border-radius: 2.6px;
      box-shadow: none;
    }

    &::-ms-fill-upper {
      background: $grey6;
      border-radius: 2.6px;
      box-shadow: none;
    }

    &::-ms-thumb {
      box-shadow: none;
      height: 25px;
      width: 25px;
      border-radius: 25px;
      background: $green1;
      cursor: pointer;
    }
  }
}

.label {
  width: 100%;
  margin-top: 1rem;
  display: inline-block;

  small {
    color: $grey3;

    &.right {
      float: right;
    }
  }
}

;
</style>
