<template>
  <div class="cja-toggle">
    <span class="label">{{ $t("bb.results.filter." + keyName) }}</span>
    <label class="switch">
      <input type="checkbox" :checked="value" @change="updateValue()">
      <span class="slider"></span>
    </label>
  </div>
</template>


<script>
import gtmEvents from '../../../../mixins/gtm/gtmEventsBBNew'

export default {
  mixins: [gtmEvents],
  props: ["value", "keyName"],
  methods: {
    updateValue() {
      this.$emit('input', !this.value)
      this.sendProductSearch(this.keyName, !this.value)
    }
  }
}
</script>


<style lang='scss' scoped>
@import "../../../../assets/css/colors.sass";

.cja-toggle {
  width: 100%;
  vertical-align: middle;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;

  .label {
    display: flex;
    width: 100%;
  }

  .switch {
    position: relative;
    display: flex;
    float: right;
    width: 45px;
    min-width: 45px;
    height: 25px;
    margin-left: 1rem;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 25px;
    background-color: $grey6;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 19px;
      width: 19px;
      left: 3px;
      bottom: 3px;
      border-radius: 50%;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }

  input {
    &:checked+.slider {
      background-color: $green6;
    }

    &:focus+.slider {
      box-shadow: 0 0 1px $grey6;
    }

    &:checked+.slider:before {
      -webkit-transform: translateX(19px);
      -ms-transform: translateX(19px);
      transform: translateX(19px);
    }
  }

  .label {
    word-break: break-word;
  }
}

;
</style>