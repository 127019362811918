<template>
  <div class="switch-group">
    <div class="cja-toggle" v-for="option in options" :key="option.cggId">
      <label class="switch" :class="{ 'selected': value.includes(option.cggId) }">
        <input type="checkbox" :name="option.cggId" :checked="values[option.cggId].value"
          @change="updateValue(option.cggId)">
        <span class="tick m-cgg-icon--boxes-tick"></span>
      </label>
      <span class="label">{{ values[option.cggId].name }}</span>
    </div>
  </div>
</template>

<script>
import gtmEvents from '../../../../mixins/gtm/gtmEventsBBNew'

export default {
  mixins: [gtmEvents],
  props: ['value'],
  data() {
    return {
      values: {},
      options: []
    }
  },
  watch: {
    value: function () {
      this.getVModel()
    }
  },
  created() {
    var endpoint = this.$store.getters.providersEndpoint

    this.$http.get(process.env.VUE_APP_CJA_URL + endpoint)
      .then((result) => {
        this.options = result.data.providers
        this.getVModel()
        this.emitInput()
      })
  },
  methods: {
    updateValue(option) {
      this.values[option].value = !this.values[option].value
      var res = this.emitInput()
      this.sendProductSearch("providers", res)
    },
    emitInput() {
      var res = []
      for (let v in this.values) {
        if (this.values[v].value) {
          res.push(this.values[v].key)
        }
      }
      this.$emit('input', res)
      return res
    },
    getVModel() {
      for (let i = 0; i < this.options.length; i++) {
        var res = {
          key: this.options[i].cggId,
          name: this.options[i].name ? this.options[i].name : this.options[i].cggId,
          value: this.value.includes(this.options[i].cggId)
        }
        this.values[this.options[i].cggId] = res
      }
      this.$store.dispatch('setProvidersList', this.values)
    },
    /**
     * De-selects all selected providers
     */
    reset() {
      for (let v in this.values) {
        this.values[v].value = false
      }
      this.emitInput()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors.sass";

.switch-group {
  min-height: 110px;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .label {
    padding-bottom: 1rem;
  }

  .cja-toggle {
    width: 100%;
    vertical-align: middle;
    padding-top: 1rem;
    display: flex;

    .switch {
      position: relative;
      display: inline-block;
      width: 2.5rem;
      min-width: 2.5rem;
      height: 2.5rem;
      margin-right: 1rem;
      background-color: transparent;
      border-radius: 0.5rem;
      border: solid 1px $grey5;
      cursor: pointer;

      &.selected {
        border-color: $green1;
        background-color: $green1;
      }

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .tick {
        color: white;
        line-height: 2.4rem;
        position: absolute;
        left: 0.4rem;
      }
    }

    .label {
      word-break: break-word;
    }
  }
};
</style>
