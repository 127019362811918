<template>
    <div>
        <slot  v-if="isA" name="A"></slot>
        <slot  v-if="isB" name="B"></slot>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
     ...mapGetters([
      'data',
      'abTestEndpoint'
    ]),
    props: ["experimentName"],
    data() {
        return {
            isA: false,
            isB: true
        }
    },
    created() {
        this.getAbVersion(this.experimentName)
    },
    methods: {
        getAbVersion(expriment) {
            const vm = this;
            var endpoint = "/core/apis/data/abTestServiceLookup"
            var payload = {
                experimentName: expriment,
                journeyId: this.$store.getters.data.journeyId
            };
            this.$http.put(endpoint, payload)
                .then(function(response) {
                    if (response.data.version != null && response.data.version) {
                        if (response.data.version === 'variant') {
                            vm.isA = false
                            vm.isB = true
                        } else {
                            vm.isA = true
                            vm.isB = false
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error);
                });
        }
    }
}
</script>