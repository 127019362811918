import $ from "jquery";

const state = {
  data: {
    journeyId: "",
    landline: "notUsing",
    lang: "pt",
    page: "1",
    pageSize: "50",
    pageType: "",
    postcode: "",
    firstMonthlyPayment: "100",
    avgMonthlyCost: "100",
    category: "month24",
    comingFromFunnel: "false",
    downloadSpeed: "12",
    mainInternetUsage: [],
    premiumPackages: [],
    provider: [],
    sortBy: ["avgMonthlyCost+", "dspeed-", "noOfChannels-"],
    hasBox: true,
    tvImportant: "notImportant",
    addSim: false,
    avgToggle: false,
    addBroadbandLaptop: false,
    numOfSim: 1,
    mobileData: 0,
    broadbandData: 0,
    contractLength: 0,
    bestOffersShow: false,
    thankYou: false,
  },
  endpoints: {
    products: "/api/broadband/v2/plans/",
    providers: "/api/telcoprovider/v2/broadbandProvidersOrderByRanking",
    applyProductData: "/core/apis/data/applyProductData",
    leadCapturePopup: "/core/apis/data/leadCapturePopup",
  },
  resetData: {
    firstMonthlyPayment: "100",
    avgMonthlyCost: "100",
    category: "month24",
    comingFromFunnel: "false",
    downloadSpeed: "200",
    mainInternetUsage: [],
    premiumPackages: [],
    provider: [],
    hasBox: true,
    tvImportant: "notImportant",
    landline: "notUsing",
    sortBy: ["avgMonthlyCost+", "dspeed-", "noOfChannels-"],
    addSim: false,
    addBroadbandLaptop: false,
    numOfSim: 1,
    mobileData: 0,
    broadbandData: 0,
    contractLength: 0,
  },
  products: {},
  filterOpen: true,
  showAllProducts: false,
  sorting: {
    activeColumn: "avgMonthlyCost",
    columns: [
      { name: "provider", mobile: false },
      { name: "service", mobile: true },
      {
        name: "noOfChannels",
        sortParam: "noOfChannels",
        mobile: true,
      },
      {
        name: "downloadSpeed",
        sortParam: "dspeed",
        direction: "ASC",
        mobile: false,
      },
      {
        name: "avgMonthlyCostDeco",
        sortParam: "avgMonthlyCost",
        direction: "ASC",
        mobile: true,
      },
    ],
  },
  tvImportantOptions: ["notImportant", "basic", "medium", "high"],
  premiumPackagesOptions: [
    {
      category: "sport",
      items: [
        "elevenSports",
        "benficaTV",
        "PFC",
        "sportTV",
        "sportTVHD",
        "sportTVHDMultiscreen",
        "fightNetwork",
      ],
    },
    {
      category: "moviesSeries",
      items: ["tvCine", "meoFilmesESeries", "sundanceTvOnDemand", "NOSStudios"],
    },
    {
      category: "adults",
      items: [
        "vividRedHD",
        "hot",
        "hotTaboo",
        "hotMan",
        "packAdultos",
        "packAdultosLight",
        "packPenthouseHD",
        "playboyTV",
        "bodySex",
        "hustlerTV",
        "packHot",
        "packHotFull",
        "hustlerTVAndBlueHustler",
        "adultosIlimitado",
        "playboyTVPlusVenus",
        "venus",
      ],
    },
    {
      category: "entertainment",
      items: [
        "globoNow",
        "globoNowAndPFC",
        "stingrayKaraoke",
        "stingrayMusic",
        "packStingrayPremium",
        "stingrayClassica",
        "disneyOnDemand",
        "CNPremium",
      ],
    },
    {
      category: "tematics",
      items: [
        "cacavision",
        "cacaEPesca",
        "dogTV",
        "toros",
        "campoPequenoTV",
        "torosAndCampoPequenoTV",
        "torosTemporada",
        "campoPequenoTVTemp",
      ],
    },
    {
      category: "international",
      items: [
        "packAsia",
        "tcv",
        "canaisRAI",
        "packSpain",
        "packFranceTV",
        "setAsia",
        "setMax",
        "zeeTV",
      ],
    },
  ],
  categoryOptions: ["month24", "month12", "month6", "month0"],
  providersList: {},
  name: undefined,
  phone: undefined,
};

const getters = {
  data: (state) => {
    return state.data;
  },
  resetActions: (state) => {
    /**
     * Returns a JSON with the actions made by a reset, sorry I know it's a big confusing method (Needed for GTM Events).
     *
     * This method compares the current data JSON against the resetData JSON and returns a JSON with the changes that will be
     * made if a reset is applied.
     *
     * It's only used to populate the label in the productSearch GTM event, when the user clicks the "Show All" button.
     *
     */
    var res = {};
    for (let el in state.data) {
      let rowA = state.data[el];
      let rowB = state.resetData[el];
      if (rowB !== undefined && rowB != rowA) {
        if (Array.isArray(rowA) && Array.isArray(rowB)) {
          if (rowA.length > 0) {
            for (let i = 0; i < rowA.length; i++) {
              if (!rowB.includes(rowA[i])) {
                res[rowA[i]] = false;
              }
            }
          }
          if (rowB.length > 0) {
            for (let i = 0; i < rowB.length; i++) {
              if (!rowA.includes(rowB[i])) {
                res[rowB[i]] = true;
              }
            }
          }
        } else {
          res[el] = rowB;
        }
      }
    }
    return res;
  },
  queryString: (state) => {
    return $.param(state.data, true);
  },
  productsEndpoint: (state) => {
    return state.endpoints.products + state.data.category;
  },
  providersEndpoint: (state) => {
    return state.endpoints.providers;
  },
  applyProductDataEndpoint: (state) => {
    return state.endpoints.applyProductData;
  },
  leadCapturePopupEndpoint: (state) => {
    return state.endpoints.leadCapturePopup;
  },
  products: (state) => {
    return state.products;
  },
  sorting: (state) => {
    return state.sorting;
  },
  activeColumn: (state) => {
    return state.sorting.activeColumn;
  },
  filterOpen: (state) => {
    return state.filterOpen;
  },
  headerItems: (state) => {
    return state.headerItems;
  },
  premiumPackagesOptions: (state) => {
    return state.premiumPackagesOptions;
  },
  tvFeatureOptions: (state) => {
    return state.tvFeatureOptions;
  },
  mainInternetUsageOptions: (state) => {
    return state.mainInternetUsageOptions;
  },
  tvImportantOptions: (state) => {
    return state.tvImportantOptions;
  },
  landlineOptions: (state) => {
    return state.landlineOptions;
  },
  categoryOptions: (state) => {
    return state.categoryOptions;
  },
  providersList: (state) => {
    return state.providersList;
  },
  name: (state) => {
    return state.name;
  },
  phone: (state) => {
    return state.phone;
  },
  showAllProducts: (state) => {
    return state.showAllProducts;
  },
  avgToggle: (state) => {
    return state.data.avgToggle;
  },
  thankYou: (state) => {
    return state.data.thankYou;
  },
};

const mutations = {
  setDataFromQuery: (state, payload) => {
    if (
      payload.mainInternetUsage &&
      !Array.isArray(payload.mainInternetUsage)
    ) {
      payload.mainInternetUsage = [payload.mainInternetUsage];
    }

    if (payload.premiumPackages && !Array.isArray(payload.premiumPackages)) {
      payload.premiumPackages = [payload.premiumPackages];
    }

    if (payload.provider && !Array.isArray(payload.provider)) {
      payload.provider = [payload.provider];
    }

    if (payload.tvFeature && !Array.isArray(payload.tvFeature)) {
      payload.tvFeature = [payload.tvFeature];
    }

    Object.assign(state.data, payload);
  },
  updateData: (state, payload) => {
    state.data[payload.key] = payload.value;
  },
  resetData: (state) => {
    Object.assign(state.data, state.resetData);
  },
  setProducts: (state, payload) => {
    state.products = payload;
  },
  updateProducts: (state, payload) => {
    state.data.showAllProducts = false;
    if (payload.featuredProducts) {
      state.products.featuredProducts = state.products.featuredProducts.concat(
        payload.featuredProducts
      );
    }
    state.products.products = state.products.products.concat(payload.products);
    state.products.timestamp = payload.timestamp;
  },
  setActiveColumn: (state, payload) => {
    state.sorting.activeColumn = payload;
  },
  toggleFilterOpen: (state) => {
    state.filterOpen = !state.filterOpen;
  },
  setProvidersList: (state, payload) => {
    state.providersList = payload;
  },
  setName: (state, payload) => {
    state.name = payload;
  },
  setPhone: (state, payload) => {
    state.phone = payload;
  },
  setShowAllProducts: (state, payload) => {
    state.showAllProducts = payload;
  },
  thankYou: (state, payload) => {
    state.data.thankYou = payload;
  }
};

const actions = {
  setData({ commit }, payload) {
    commit("setData", payload);
  },
  setDataFromQuery({ commit }, payload) {
    commit("setDataFromQuery", payload);
  },
  updateData({ commit }, payload) {
    commit("updateData", payload);
  },
  resetData({ commit }) {
    commit("resetData");
  },
  setProducts({ commit }, payload) {
    commit("setProducts", payload);
  },
  updateProducts({ commit }, payload) {
    commit("updateProducts", payload);
  },
  setActiveColumn({ commit }, payload) {
    commit("setActiveColumn", payload);
  },
  toggleFilterOpen({ commit }) {
    commit("toggleFilterOpen");
  },
  setProvidersList({ commit }, payload) {
    commit("setProvidersList", payload);
  },
  setName({ commit }, payload) {
    commit("setName", payload);
  },
  setPhone({ commit }, payload) {
    commit("setPhone", payload);
  },
  toggleShowAllProduct({ commit }, payload) {
    commit("setShowAllProducts", payload);
  },
  setThankYou({commit}, payload) {
    commit("thankYou", payload)
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
