<template>
  <div>
    <div :id="product.cggId" class="result">
      <div
        @click.stop="doAction('white-space')"
        class="content"
        :class="{ clickable: showButton }"
      >
        <div class="content-column provider">
          <div @click.stop="doAction('image')" class="provider-image">
            <img :src="imageBaseURL + product.image" :alt="product.name" />
          </div>
        </div>
        <div class="content-column service">
          <div class="service-type info upColumns">
            {{ product.offer.service }}
          </div>
          <div v-if="isMobile" class="coverage label">
            <em class="m-cgg m-cgg-icon--location"></em>
            {{ $t(getCoverage(product.offer.isAvailableAtAddress)) }}
          </div>
        </div>
        <div
          class="content-column channels upColumns"
          :class="activeColumn == 'noOfChannels' ? 'selected' : ''"
        >
          <div class="no-of-channels info">{{ product.tv.noOfChannels }}</div>
          <div class="channels-label label">Canais</div>
        </div>
        <div
          v-if="!isMobile"
          class="content-column dspeed upColumns"
          :class="activeColumn == 'downloadSpeed' ? 'selected' : ''"
        >
          <div class="download-speed info">
            {{ product.speed.maxSpeedDownload }}
          </div>
          <div class="dspeed-label label">
            {{ $t("widget.locale.symbol.mbpersec") }}
          </div>
        </div>
        <div
          class="content-column cost"
          :class="activeColumn == 'avgMonthlyCost' ? 'selected' : ''"
        >
          <div class="monthly-cost info">
            <div class="upColumns">
              {{ roundPrice(displayPrice) + $t("widget.locale.symbol.ccy") }}
              <em
                v-if="false"
                class="m-cgg-icon--i-tooltip"
                v-popover:bottom.hover="
                  $t('widget.info.item.avg-monthly-cost.column.popover.tooltip')
                "
              ></em>
            </div>
            <div class="downColumns">
              {{
                "1ª Fatura " +
                product.fee.firstMonthlyPayment +
                $t("widget.locale.symbol.ccy")
              }}
            </div>
          </div>
        </div>
        <div
          v-if="
            product.provider.cggId != this.$store.getters.data.currentProvider
          "
          class="content-column action"
        >
          <div
            v-if="product.name != 'Vodafone'"
            @click.stop="doAction('apply')"
            class="action-button"
          >
            <a
              :data-toggle="isCTA ? 'modal' : ''"
              :data-target="isCTA ? '#leadCapture' : ''"
              role="button"
              >Aderir</a
            >
          </div>
          <div v-if="!isMobile && showButton" class="coverage label">
            <em class="m-cgg m-cgg-icon--location"></em>
            {{ $t(getCoverage(product.offer.isAvailableAtAddress)) }}
          </div>
        </div>
        <div class="lowbar">
          <div
            v-if="!isMobile"
            @click.stop="doAction('footnote offer')"
            class="footnote1"
          >
            <span v-if="product.footnote[0]"
              ><em class="m-cgg m-cgg-icon--check2"></em>
              {{ product.footnote[0] }}</span
            >
          </div>
          <div
            v-if="!isMobile"
            @click.stop="doAction('footnote offer')"
            class="footnote2"
          >
            <span v-if="product.footnote[1]"
              ><em class="m-cgg m-cgg-icon--check2"></em>
              {{ product.footnote[1] }}</span
            >
          </div>
          <div @click.stop="toggleInfo" class="show-more">
            <span v-if="!showInfo"
              >{{ $t("widget.list-item.more") }}
              <em class="m-cgg-icon--caret-down"></em
            ></span>
            <span v-else
              >{{ $t("widget.list-item.less") }}
              <em class="m-cgg-icon--caret-up"></em
            ></span>
          </div>
        </div>
        <div
          v-if="product.banner.bannerDesc"
          @click.stop="doAction('promotion')"
          class="banner"
          :class="product.banner.bannerType"
        >
          <div class="icon">
            <em
              v-if="product.banner.bannerType == 'exclusive'"
              class="m-cgg-icon--exclusive"
            ></em>
            <em
              v-if="product.banner.bannerType == 'popular'"
              class="m-cgg-icon--heart"
            ></em>
            <em
              v-if="product.banner.bannerType == 'timeLimited'"
              class="m-cgg-icon--timer"
            ></em>
            <em
              v-if="product.banner.bannerType == 'bestValueAward'"
              class="m-cgg-icon--star"
            ></em>
            <em
              v-if="product.banner.bannerType == 'welcomeGift'"
              class="m-cgg-icon--giftbox"
            ></em>
          </div>
          <div class="message">
            <span>{{ product.banner.bannerDesc }}</span>
          </div>
          <div class="ribbon">
            <svg width="15" height="30">
              <path fill="#2e86bc" d="M0 0 15 0 0 15 15 30 0 30 Z"></path>
            </svg>
          </div>
        </div>
        <div @click.stop="doAction('name')" class="product-description">
          <span
            ><strong>{{ product.description }}</strong></span
          >
        </div>
      </div>
      <transition>
        <div v-if="showInfo" class="more-info">
          <div class="container more-info-header">
            <div class="row">
              <div class="col-4">
                <hr />
              </div>
              <div class="col-4">
                <span
                  ><strong
                    ><em>{{
                      $t("widget.list-item.offer-reference")
                    }}</em></strong
                  ></span
                >
              </div>
              <div class="col-4">
                <hr />
              </div>
            </div>
          </div>
          <div class="container more-info-left">
            <div class="row">
              <div class="col-12">
                <h2>
                  <strong>{{ $t("widget.pros-cons.title") }}</strong>
                </h2>
                <div
                  class="feature"
                  v-for="(pro, index) in product.feature"
                  :key="'pro' + index"
                >
                  <div class="feature-icon">
                    <em class="success m-cgg-icon--ciab-tick"></em>
                  </div>
                  <div class="feature-text" v-html="pro"></div>
                </div>
                <div
                  class="feature"
                  v-for="(con, index) in product.specification"
                  :key="'con' + index"
                >
                  <div class="feature-icon">
                    <em class="danger m-cgg-icon--ciab-minus"></em>
                  </div>
                  <div class="feature-text" v-html="con"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h2
                  class="table-header"
                  @click.stop="toggleAditionalCaracteristics"
                >
                  <strong
                    >{{ $t("widget.additional-pros-cons.title") }}
                    <em
                      :class="
                        showAditionalCaracteristics
                          ? 'm-cgg-icon--arrow-up'
                          : 'm-cgg-icon--arrow-down'
                      "
                    ></em
                  ></strong>
                </h2>
                <div v-show="showAditionalCaracteristics">
                  <div
                    class="feature"
                    v-for="(feature, index) in product.additionalFeature"
                    :key="'feature' + index"
                  >
                    <div class="feature-icon">
                      <em class="success m-cgg-icon--ciab-tick"></em>
                    </div>
                    <div class="feature-text" v-html="feature"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h2 class="table-header" @click.stop="toggleMoreInfo">
                  <strong
                    >{{ $t("widget.info.heading.more-info") }}
                    <em
                      :class="
                        showMoreInfo
                          ? 'm-cgg-icon--arrow-up'
                          : 'm-cgg-icon--arrow-down'
                      "
                    ></em
                  ></strong>
                </h2>
                <div v-if="showMoreInfo">
                  <table>
                    <tr>
                      <th scope="row">
                        <span>{{
                          $t("widget.info.heading.more-info.laptopTablet")
                        }}</span>
                      </th>
                      <th scope="row"></th>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.laptopTablet.download-speed")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.speed.maxSpeedDownload
                            ? product.speed.maxSpeedDownload +
                              " " +
                              $t("widget.locale.symbol.mbpersec")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.laptopTablet.upload-speed")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.speed.maxSpeedUpload
                            ? product.speed.maxSpeedUpload +
                              " " +
                              $t("widget.locale.symbol.mbpersec")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.laptopTablet.connection-type")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.moreInfo.connectionType ||
                          product.offer.service ||
                          $t(none())
                        }}</span>
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <th scope="row">
                        <span>{{
                          $t("widget.info.heading.more-info.tvChannel")
                        }}</span>
                      </th>
                      <th scope="row"></th>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.tvChannel.channel-num")
                        }}</span>
                      </td>
                      <td>
                        <a
                          :href="product.tv.channelsDetail"
                          :name="$t('widget.info.item.tvChannel.channel-num')"
                          target="_blank"
                          >{{ product.tv.noOfChannels || $t(none()) }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          >{{
                            $t("widget.info.item.tvChannel.uniqueBaseChannels")
                          }}
                          <em
                            v-if="product.tv.uniqueBaseChannelsToolTipMessage"
                            v-popover:top.hover="
                              product.tv.uniqueBaseChannelsToolTipMessage
                            "
                            class="success m-cgg-icon--i-tooltip"
                          ></em
                        ></span>
                      </td>
                      <td>
                        <span>{{
                          product.tv.uniqueBaseChannels || $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          >{{
                            $t(
                              "widget.info.item.tvChannel.versionsDifferentLanguagesResolutions"
                            )
                          }}
                          <em
                            v-if="
                              product.tv.versDiffLangResolutionsToolTipMessage
                            "
                            v-popover:top.hover="
                              product.tv.versDiffLangResolutionsToolTipMessage
                            "
                            class="success m-cgg-icon--i-tooltip"
                          ></em
                        ></span>
                      </td>
                      <td>
                        <span>{{
                          product.tv.versionsDifferentLanguagesResolutions ||
                          $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.tvChannel.exclusiveChannels")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.tv.exclusiveChannels || $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.tvChannel.interactiveChannels")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.tv.interactiveChannels || $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.tvChannel.radioStations")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.tv.radioStations || $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.tvChannel.premium-channel")
                        }}</span>
                      </td>
                      <td>
                        <a
                          v-if="product.tv.premiumChannelsDetail"
                          :name="
                            $t('widget.info.item.tvChannel.premium-channel')
                          "
                          :href="product.tv.premiumChannelsDetail"
                          target="_blank"
                          >{{ $t("widget.bb.lookhere") }}</a
                        >
                        <span v-else>{{ $t(none()) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.tvChannel.numberOfTvBoxesToAdd")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.tv.numberOfTvBoxesToAdd || $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.tvChannel.additionalBoxCost")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.tv.additionalBoxCost
                            ? product.tv.additionalBoxCost +
                              " " +
                              $t("widget.locale.symbol.ccy")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.tvChannel.tvConnectionType")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.tv.tvConnectionType || $t(none())
                        }}</span>
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <th scope="row">
                        <span>{{
                          $t("widget.info.heading.more-info.landline")
                        }}</span>
                      </th>
                      <th scope="row"></th>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.landline.local-call-mins")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.landline.landlineLocalMins == "unlimited"
                            ? $t("widget.result.unlimited.value")
                            : product.landline.landlineLocalMins +
                                " " +
                                $t("widget.locale.symbol.minute") || $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t(
                            "widget.info.item.landline.international-call-mins"
                          )
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.landline.landlineInternationalMins ==
                          "unlimited"
                            ? $t("widget.result.unlimited.value")
                            : product.landline.landlineInternationalMins +
                                " " +
                                $t("widget.locale.symbol.minute") || $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t(
                            "widget.info.item.landline.international-country-included"
                          )
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.landline.noOfInternationalDest || $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.landline.fixed-line-call-cost")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.landline.landlineFixedNetLocalCallCost
                            ? roundPrice(
                                product.landline.landlineFixedNetLocalCallCost
                              ) +
                              " " +
                              $t("widget.locale.symbol.price-per-minute")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.landline.mobile-net-call-cost")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.landline.landlineMobileNetLocalCallCost
                            ? roundPrice(
                                product.landline.landlineMobileNetLocalCallCost
                              ) +
                              " " +
                              $t("widget.locale.symbol.price-per-minute")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t(
                            "widget.info.item.landline.international-call-detail"
                          )
                        }}</span>
                      </td>
                      <td>
                        <a
                          v-if="product.landline.landlineInternationalCallLink"
                          :name="
                            $t(
                              'widget.info.item.landline.international-call-detail'
                            )
                          "
                          :href="product.landline.landlineInternationalCallLink"
                          target="_blank"
                          >{{ $t("widget.bb.lookhere") }}</a
                        >
                        <span v-else>{{ $t(none()) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.landline.transfer-cost")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.landline.landlineTransferCost
                            ? roundPrice(
                                product.landline.landlineTransferCost
                              ) +
                              " " +
                              $t("widget.locale.symbol.ccy")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <th scope="row">
                        <span>{{
                          $t("widget.info.heading.more-info.mobilePhone")
                        }}</span>
                      </th>
                      <th scope="row"></th>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t(
                            "widget.info.item.mobilePhone.international-call-cost"
                          )
                        }}</span>
                      </td>
                      <td>
                        <a
                          v-if="product.mobileInfo.mobInternationalCostLink"
                          :name="
                            $t(
                              'widget.info.item.mobilePhone.international-call-cost'
                            )
                          "
                          :href="product.mobileInfo.mobInternationalCostLink"
                          target="_blank"
                          >{{ $t("widget.bb.lookhere") }}</a
                        >
                        <span v-else>{{ $t(none()) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.mobilePhone.roaming-cost")
                        }}</span>
                      </td>
                      <td>
                        <a
                          v-if="product.mobileInfo.roamingCostLink"
                          :name="
                            $t('widget.info.item.mobilePhone.roaming-cost')
                          "
                          :href="product.mobileInfo.roamingCostLink"
                          target="_blank"
                          >{{ $t("widget.bb.lookhere") }}</a
                        >
                        <span v-else>{{ $t(none()) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t(
                            "widget.info.item.mobilePhone.additional-data-cost"
                          )
                        }}</span>
                      </td>
                      <td>
                        <a
                          v-if="product.mobileInfo.mobAddDataCostLink"
                          :name="
                            $t(
                              'widget.info.item.mobilePhone.additional-data-cost'
                            )
                          "
                          :href="product.mobileInfo.mobAddDataCostLink"
                          target="_blank"
                          >{{ $t("widget.bb.lookhere") }}</a
                        >
                        <span v-else>{{ $t(none()) }}</span>
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <th scope="row">
                        <span>{{
                          $t("widget.info.heading.more-info.mobileInternet")
                        }}</span>
                      </th>
                      <th scope="row"></th>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.mobileInternet.download-speed")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.mobileInternet.mobileBBDownloadSpeed
                            ? product.mobileInternet.mobileBBDownloadSpeed +
                              " " +
                              $t("widget.locale.symbol.mbpersec")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.mobileInternet.upload-speed")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.mobileInternet.mobileBBUploadSpeed
                            ? product.mobileInternet.mobileBBUploadSpeed +
                              " " +
                              $t("widget.locale.symbol.mbpersec")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.mobileInternet.data-limit")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.mobileInternet.mobileBBData || $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.mobileInternet.mobileBBAddData")
                        }}</span>
                      </td>
                      <td>
                        <a
                          v-if="product.mobileInternet.mobileBBAddDataLink"
                          :name="
                            $t(
                              'widget.info.item.mobileInternet.mobileBBAddData'
                            )
                          "
                          :href="product.mobileInternet.mobileBBAddDataLink"
                          target="_blank"
                          >{{ $t("widget.bb.lookhere") }}</a
                        >
                        <span v-else>{{ $t(none()) }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h2 class="table-header" @click="toggleCosts">
                  <strong
                    >{{ $t("widget.info.heading.price-calculation") }}
                    <em
                      :class="
                        showCosts
                          ? 'm-cgg-icon--arrow-up'
                          : 'm-cgg-icon--arrow-down'
                      "
                    ></em
                  ></strong>
                </h2>
                <div v-if="showCosts">
                  <table>
                    <tr>
                      <th scope="row"></th>
                      <th scope="row"></th>
                    </tr>
                    <tr>
                      <td>
                        <span>{{
                          $t("widget.info.item.contract-length")
                        }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.moreInfo.contractLength || $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{ $t("widget.info.item.monthly-cost") }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.fee.monthlyCost
                            ? roundPrice(product.fee.monthlyCost) +
                              " " +
                              $t("widget.locale.symbol.price-per-month")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          >{{ $t("widget.info.item.discount-detail") }}
                          <em
                            v-if="product.fee.discountValueToolTipMessage"
                            v-popover:top.hover="
                              product.fee.discountValueToolTipMessage
                            "
                            class="success m-cgg-icon--i-tooltip"
                          ></em
                        ></span>
                      </td>
                      <td>
                        <span>{{
                          product.fee.contractDiscount
                            ? roundPrice(product.fee.contractDiscount) +
                              " " +
                              $t("widget.locale.symbol.ccy")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          >{{ $t("widget.info.item.otherAdditionalCosts") }}
                          <em
                            v-if="
                              product.fee.otherAdditionalCostsToolTipMessage
                            "
                            v-popover:top.hover="
                              product.fee.otherAdditionalCostsToolTipMessage
                            "
                            class="success m-cgg-icon--i-tooltip"
                          ></em
                        ></span>
                      </td>
                      <td>
                        <span>{{
                          product.fee.otherAdditionalCosts
                            ? roundPrice(product.fee.otherAdditionalCosts) +
                              " " +
                              $t("widget.locale.symbol.ccy")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{{ $t("widget.info.item.total-contract") }}</span>
                      </td>
                      <td>
                        <span>{{
                          product.fee.priceContract
                            ? roundPrice(product.fee.priceContract) +
                              " " +
                              $t("widget.locale.symbol.ccy")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          >{{ $t("widget.info.item.avg-monthly-cost") }}
                          <em
                            v-popover:top.hover="
                              $t(
                                'bb.results.sideinfo.productInfo.cost.monthlyCosts.tooltip'
                              )
                            "
                            class="success m-cgg-icon--i-tooltip"
                          ></em
                        ></span>
                      </td>
                      <td>
                        <span>{{
                          product.fee.avgMonthlyCost
                            ? roundPrice(product.fee.avgMonthlyCost) +
                              " " +
                              $t("widget.locale.symbol.ccy")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          >{{ $t("widget.info.item.totalValueContractOffer") }}
                          <em
                            v-popover:top.hover="
                              product.fee.totalValueContractOfferToolTipMessage
                            "
                            class="success m-cgg-icon--i-tooltip"
                          ></em
                        ></span>
                      </td>
                      <td>
                        <span>{{
                          product.fee.totalValueContractOffer
                            ? roundPrice(product.fee.totalValueContractOffer) +
                              " " +
                              $t("widget.locale.symbol.ccy")
                            : $t(none())
                        }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="more-info-right">
            <div class="row">
              <div class="col-12">
                <a
                  class="call-action"
                  @click="sendPurchase('call', product, index + 1)"
                  :name="$t('widget.company-details.phone-number')"
                  :href="'tel:' + $t('widget.company-details.phone-number')"
                >
                  <span>
                    <strong
                      ><em class="m-cgg-icon--call-circle"></em>
                      {{ $t("widget.company-details.phone-number") }}</strong
                    ></span
                  >
                </a>
              </div>
            </div>
            <div class="company-details">
              <div class="row">
                <div class="col-12">
                  <p>
                    <strong>{{
                      $t("widget.call-us.quoting-reference")
                    }}</strong>
                  </p>
                  <p>{{ $t("widget.company-details.quoting-reference") }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p>
                    <strong>{{ $t("widget.call-us.business-hours") }}</strong>
                  </p>
                  <p>{{ $t("widget.call-us.business-hours-details") }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h3 class="table-header" @click.stop="toggleAboutProvider">
                    <strong
                      >{{ $t("widget.title.more-about-provider") }}
                      <em
                        :class="
                          showAboutProvider
                            ? 'm-cgg-icon--arrow-up'
                            : 'm-cgg-icon--arrow-down'
                        "
                      ></em
                    ></strong>
                  </h3>
                  <span
                    v-show="showAboutProvider"
                    v-html="product.provider.about"
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div class="more-info-footer">
            <div v-if="!isMobile" class="footer-note1">
              <span v-if="product.footnote[0]"
                ><em class="m-cgg m-cgg-icon--check2"></em>
                {{ product.footnote[0] }}</span
              >
            </div>
            <div v-if="!isMobile" class="footer-note2">
              <span v-if="product.footnote[1]"
                ><em class="m-cgg m-cgg-icon--check2"></em>
                {{ product.footnote[1] }}</span
              >
            </div>
            <div @click="toggleInfo" class="footer-show-more">
              <span v-if="!showInfo"
                >{{ $t("widget.list-item.more") }}
                <em class="m-cgg-icon--caret-down"></em
              ></span>
              <span v-else
                >{{ $t("widget.list-item.less") }}
                <em class="m-cgg-icon--caret-up"></em
              ></span>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
import { eventBus } from "../../main";
import screenSizeDetector from "../../mixins/screenSizeDetector";
import gtmEvents from "../../mixins/gtm/gtmEvents";

export default {
  props: ["product", "index"],
  mixins: [screenSizeDetector, gtmEvents],
  data() {
    return {
      showInfo: false,
      showMoreInfo: false,
      showCosts: false,
      showAditionalCaracteristics: false,
      showAboutProvider: false,
      imageBaseURL: process.env.VUE_APP_CJA_URL,
    };
  },
  computed: {
    ...mapGetters([
      "data",
      "applyProductDataEndpoint",
      "activeColumn",
      "avgToggle",
    ]),
    isCTA() {
      return true;
    },
    displayPrice() {
      return (
        this.product.fee.avgMonthlyCost + this.product.fee.totalExtraSIMCost
      );
    },
    showButton() {
      return (
        this.product.hasClickout ||
        this.product.hasApplyButton ||
        this.product.hasApplicationForm ||
        this.product.showCTALeadCapture
      );
    },
  },
  methods: {
    /**
     * Translates the coverage response from the CIAB
     *
     * @param {Number} isAvailable The number referencing the availability status in the given postcode, for
     * the given product.
     *
     * @returns {String} Translation key to the i18n translator
     */
    getCoverage(isAvailable) {
      return "widget.isAvailableAtAddress." + "notConfirm";
    },
    /**
     * Toggles the More Info div.
     *
     * Launches the GTM Event 'productDetail'.
     */
    toggleInfo() {
      this.showInfo = !this.showInfo;
      if (this.showInfo) {
        this.sendDetail(this.product, this.index + 1);
      }
    },
    /**
     * Toggles the more info table.
     */
    toggleMoreInfo() {
      this.showMoreInfo = !this.showMoreInfo;
    },
    /**
     * Toggles the more info table.
     */
    toggleCosts() {
      this.showCosts = !this.showCosts;
    },
    /**
     * Toggles the aditional caracteristics list.
     */
    toggleAditionalCaracteristics() {
      this.showAditionalCaracteristics = !this.showAditionalCaracteristics;
    },
    /**
     * Toggles the about provider section.
     */
    toggleAboutProvider() {
      this.showAboutProvider = !this.showAboutProvider;
    },
    /**
     * Returns the i18n translation key for not available.
     *
     * @returns {String} Translation key for N/A.
     */
    none() {
      return "widget.info.item.none";
    },
    /**
     * Rounds a number/ price with 2 decimal floating point
     *
     * @param {Number} price The number/price to be rounded
     *
     * @returns {Number} The rounded number
     */
    roundPrice(price) {
      return Number.parseFloat(price).toLocaleString("pt-PT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    /**
     * Redirects to the Click Out URL.
     *
     * Sends an applyProductData request to CIAB and
     * redirects to the redirectUrl.
     *
     * @param {String} url The url to redirect to.
     */
    clickOut(url) {
      this.applyProductData("CLICK_APPLY", "apply");
      window.open(url, "_blank");
    },
    /**
     * Redirects to the Application Form.
     *
     * Sends an applyProductData request to CIAB and
     * redirects to the product Application Form.
     */
    applicationForm() {
      this.applyProductData("OPEN_APPLICATION_FORM", "apply");
      var redirectRequest = {
        postcode: this.data.postcode,
        addSim: this.data.addSim,
        journeyId: this.data.journeyId,
        premiumPackages: this.data.premiumPackages,
        product: this.product.name,
        cggId: this.product.cggId,
        product_code: this.product.cggId,
      };

      var stringRequest = Object.keys(redirectRequest)
        .map(function (key) {
          return (
            encodeURIComponent(key) +
            "=" +
            encodeURIComponent(redirectRequest[key])
          );
        })
        .join("&");

      window.location.href = "/tv-net-voz/adesao?" + stringRequest;
    },
    /**
     * Opens the lead capture modal.
     *
     * Sends the applyProductData request to CIAB and
     * emits a setLeadCaptureEvent with the selected product.
     */
    openLeadCapture() {
      $("#leadCapture").modal("show");
      let postObject = JSON.stringify({
        event: "iFrameEvent",
        param_0: "SSEL-Telco",
        param_1: "ComparaJa",
        param_2: "scrollRequest",
      });
      parent.postMessage(postObject, "https://www.deco.proteste.pt");
      this.applyProductData("SHOW_CTA_LEAD_CAPTURE", "apply");
      eventBus.$emit("setLeadCapture", this.product);
    },
    /**
     * Creates and sends the request applyProductData to CIAB.
     *
     * @param {String} action The action that triggers the request.
     * @param {String} intent The intection of the user click.
     */
    applyProductData(action, intent) {
      var postRequest = {
        action,
        category: this.data.category,
        cggId: this.product.cggId,
        intent,
        journeyId: this.data.journeyId,
        path: this.$route.path,
        position: this.index,
        viewId: this.product.viewId,
        providerCggId: this.product.provider.cggId,
      };
      this.$http.post(
        process.env.VUE_APP_CJA_URL + this.applyProductDataEndpoint,
        postRequest
      );
    },
    /**
     * Selects and executes the action associated with the clicked product.
     *
     * Launches the GTM Event Purchase.
     *
     * @param {String} source The source of the click.
     */
    doAction(source) {
      if (this.showButton) {
        this.sendPurchase(source, this.product, this.index + 1);

        this.openLeadCapture();
        window.scrollTo(0, screen.height / 2);
      }
    },
    goToCheckout() {
      this.applyProductData("OPEN_CHECKOUT", "apply");
      this.sendGTMSimpleEvent("formSubmit", "online checkout", 0, []);
      window.open(
        "/tv-net-voz/aplicar?journeyId=" +
          this.data.journeyId +
          "&cggId=" +
          this.product.cggId +
          `&provider=${this.product.provider.cggId}`,
        "_self"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
$dark-blue: #056f9d;
$blue: #2e86bc;
$light-blue: #519bc6;
$lightest-blue: #e6f1f9;
$yellow: #facb1a;
$light-yellow: #f5e397;
$lightest-yellow: #f6edc9;
$orange: #f68835;
$dark-orange: #e45f2c;
$hr-color: #cccbc8;
$text-grey: #969190;
$text-black: #312f2e;
$color-success: #77aa43;
$color-danger: #e20613;
$anchor-color: #337ab7;
$anchor-hover-color: #23527c;
$banner-popular: #803580;
$banner-popular-light: #bd5ebd;
$banner-time-limited: #98c11e;
$banner-time-limited-light: #c4e65f;
$banner-best-value-award: #056f9d;
$banner-best-value-award-light: #2e86bc;
$banner-welcome-gift: #056f9d;
$banner-welcome-gift-light: #2e86bc;
$banner-exclusive: #056f9d;
$banner-exclusive-light: #2e86bc;

.current-client {
  grid-area: actionButton;
  padding-top: 32px;
  font-size: 22px;
}

.row {
  margin-bottom: 1rem;
}

.success {
  color: $color-success !important;
}

.danger {
  color: $color-danger !important;
}

.result {
  margin: 1rem;
  margin-top: 3rem;

  &.featured {
    margin-top: 1rem;
  }

  a:hover {
    text-decoration: none;
  }

  .featured-tag {
    text-align: right;

    span {
      background-color: transparent;
      font-size: 14px;
      color: $text-grey;
    }
  }

  .content {
    background-color: white;
    height: auto;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(6, 1fr);
    -ms-grid-rows: 30px 30px 30px 30px 30px 30px 30px;
    grid-template-rows: repeat(7, 30px);
    grid-template-areas: "provider service channels dspeed cost action" "provider service channels dspeed cost action" "provider service channels dspeed cost action" "provider service channels dspeed cost action" "provider service channels dspeed cost action" "provider service channels dspeed cost action" "lowbar lowbar lowbar lowbar lowbar lowbar";
    -webkit-box-shadow: 4px 4px 16px 6px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 4px 4px 16px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 4px 16px 6px rgba(0, 0, 0, 0.2);

    &.clickable {
      cursor: pointer;

      .lowbar {
        .footnote1 span,
        .footnote2 span {
          color: $anchor-color;
        }

        .footnote1 span:hover,
        .footnote2 span:hover {
          color: $anchor-hover-color;
          text-decoration: underline;
        }
      }
    }

    .content-column {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: 30px 30px 30px 30px 30px;
      grid-template-rows: repeat(5, 30px);
      padding-right: 1rem;
      padding-left: 1rem;

      > * {
        &:nth-child(1) {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }

        &:nth-child(2) {
          -ms-grid-row: 2;
          -ms-grid-column: 1;
        }

        &:nth-child(3) {
          -ms-grid-row: 3;
          -ms-grid-column: 1;
        }

        &:nth-child(4) {
          -ms-grid-row: 4;
          -ms-grid-column: 1;
        }

        &:nth-child(5) {
          -ms-grid-row: 5;
          -ms-grid-column: 1;
        }
      }

      .info {
        color: $text-black;
        font-size: 24px;
        line-height: 20px;
        font-weight: 700;
      }

      .label {
        font-size: 14px;
        color: #979290;
        text-align: center;

        small {
          padding-top: 4px;
          font-size: 9px;
          line-height: 12px;
          display: inline-block;
        }
      }

      &.selected {
        background-color: #eee;

        span.info {
          color: $dark-blue;
        }
      }

      .cost-info {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 4fr 2fr 1fr;
        grid-template-columns: 4fr 2fr 1fr;
        -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "cost-info-text cost-info-value cost-info-symbol";

        .cost-info-text {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
          grid-area: cost-info-text;
          text-align: left;
        }

        .cost-info-value {
          -ms-grid-row: 1;
          -ms-grid-column: 2;
          grid-area: cost-info-value;
          text-align: right;
        }

        .cost-info-symbol {
          -ms-grid-row: 1;
          -ms-grid-column: 3;
          grid-area: cost-info-symbol;
        }
      }
    }

    .banner {
      margin-top: -2px;
      margin-left: -2px;
      width: 100%;
      padding: 0;
      background: none;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-grid-row: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 5;
      grid-column-end: 6;

      .icon {
        color: white;
        padding: 0.5rem;
        max-height: 30px;
        width: 30px;
      }

      .message {
        color: white;
        padding: 0.5rem;
        max-height: 30px;
      }

      &.popular {
        .icon {
          background-color: $banner-popular;
        }

        .message {
          background-color: $banner-popular-light;
        }

        .ribbon {
          svg {
            path {
              fill: $banner-popular-light;
            }
          }
        }
      }

      &.timeLimited {
        .icon {
          background-color: $banner-time-limited;
        }

        .message {
          background-color: $banner-time-limited-light;
        }

        .ribbon {
          svg {
            path {
              fill: $banner-time-limited-light;
            }
          }
        }
      }

      &.bestValueAward {
        .icon {
          background-color: $banner-best-value-award;
        }

        .message {
          background-color: $banner-best-value-award-light;
        }

        .ribbon {
          svg {
            path {
              fill: $banner-best-value-award-light;
            }
          }
        }
      }

      &.welcomeGift {
        .icon {
          background-color: $banner-welcome-gift;
        }

        .message {
          background-color: $banner-welcome-gift-light;
        }

        .ribbon {
          svg {
            path {
              fill: $banner-welcome-gift-light;
            }
          }
        }
      }

      &.exclusive {
        .icon {
          background-color: $banner-exclusive;
        }

        .message {
          background-color: $banner-exclusive-light;
        }

        .ribbon {
          svg {
            path {
              fill: $banner-exclusive-light;
            }
          }
        }
      }
    }

    .product-description {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-grid-row: 2;
      grid-row: 2;
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 5;
      grid-column-end: 6;

      span {
        padding-left: 1rem;
      }
    }

    .provider {
      -ms-grid-row: 1;
      -ms-grid-row-span: 6;
      -ms-grid-column: 1;
      grid-area: provider;
      grid-template-areas: "." "." "providerImage" "." "." ".";

      .provider-image {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        grid-area: providerImage;

        img {
          max-width: 100%;
        }
      }
    }

    .service {
      -ms-grid-row: 1;
      -ms-grid-row-span: 6;
      -ms-grid-column: 2;
      grid-area: service;
      grid-template-areas: "." "." "." "serviceType" "coverage" ".";

      .service-type {
        -ms-grid-row: 4;
        -ms-grid-column: 1;
        grid-area: serviceType;
      }

      .coverage {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        grid-area: coverage;
      }
    }

    .channels {
      -ms-grid-row: 1;
      -ms-grid-row-span: 6;
      -ms-grid-column: 3;
      grid-area: channels;
      grid-template-areas: "." "." "." "noOfChannels" "channelsLabel" ".";

      .no-of-channels {
        -ms-grid-row: 4;
        -ms-grid-column: 1;
        grid-area: noOfChannels;
      }

      .channels-label {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        grid-area: channelsLabel;
      }
    }

    .dspeed {
      -ms-grid-row: 1;
      -ms-grid-row-span: 6;
      -ms-grid-column: 4;
      grid-area: dspeed;
      grid-template-areas: "." "." "." "downloadSpeed" "dspeedLabel" ".";

      .download-speed {
        -ms-grid-row: 4;
        -ms-grid-column: 1;
        grid-area: downloadSpeed;
      }

      .dspeed-label {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        grid-area: dspeedLabel;
      }
    }

    .cost {
      -ms-grid-row: 1;
      -ms-grid-row-span: 6;
      -ms-grid-column: 5;
      grid-area: cost;
      grid-template-areas: "." "discount" "discount" "monthlyCost" "avgMonthlyCost" "installationCost";

      .discount {
        -ms-grid-row: 2;
        -ms-grid-row-span: 2;
        -ms-grid-column: 1;
        grid-area: discount;
        position: relative;
        top: 0;
        right: 0;
        height: 0;
        border-style: solid;
        border-width: 0 40px 40px 0;
        border-color: transparent $light-blue transparent transparent;

        .discount-text {
          font-size: 1rem;
          color: white;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          right: -3.8rem;
          top: 0.8rem;
        }
      }

      .monthly-cost {
        -ms-grid-row: 4;
        -ms-grid-column: 1;
        grid-area: monthlyCost;
      }

      .avg-monthly-cost {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        grid-area: avgMonthlyCost;
      }

      .installation-cost {
        -ms-grid-row: 6;
        -ms-grid-column: 1;
        grid-area: installationCost;
      }
    }

    .action {
      -ms-grid-row: 1;
      -ms-grid-row-span: 6;
      -ms-grid-column: 6;
      grid-area: action;
      display: -ms-grid;
      display: grid;
      grid-template-areas: "." "." "actionButton" "actionButton" "coverage" "actionNumber";

      .action-button {
        -ms-grid-row: 3;
        -ms-grid-row-span: 2;
        -ms-grid-column: 1;
        grid-area: actionButton;
        padding: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-size: 2rem;
        width: 100%;
        font-weight: 700;
        background-color: #f68835;
        border-radius: 3px;
        color: white;
        text-decoration: none;

        &:hover {
          background-color: #e45f2c;
        }

        &.btn-v2 {
          background-color: $blue;
          border-bottom: 5px solid #076b9c;

          &:hover {
            background-color: #2e749e;
            border-color: #0c5579;
          }
        }
      }

      .coverage {
        -ms-grid-row: 4;
        -ms-grid-column: 6;
        grid-area: coverage;
      }

      &.v2 {
        grid-template-areas: "." "actionButton" "actionButton" "actionButton" "coverage" "coverage";

        .action-button {
          -ms-grid-row: 2;
          -ms-grid-row-span: 3;
          line-height: 2.2rem;
          padding: 1.5rem;
        }

        .coverage {
          -ms-grid-row: 5;
          -ms-grid-row-span: 2;
          grid-area: coverage;
        }
      }

      .action-text {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
        grid-area: actionText;
        color: $orange;
        font-size: 1.1rem;
        overflow: hidden;
      }

      .action-number {
        -ms-grid-row: 6;
        -ms-grid-column: 1;
        grid-area: actionNumber;
        color: $orange;
        font-size: 1.5rem;
        line-height: 30px;
      }
    }

    .lowbar {
      -ms-grid-row: 7;
      -ms-grid-column: 1;
      -ms-grid-column-span: 6;
      grid-area: lowbar;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: repeat(3, 1fr);
      -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "footnote1 footnote2 show-more";
      background-color: #eee;
      font-size: 14px;

      .footnote1 {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        grid-area: footnote1;
        text-align: left;
        padding: 0.7rem;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 0px;
      }

      .footnote2 {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        grid-area: footnote2;
        text-align: left;
        padding: 0.7rem;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 0px;
      }

      .show-more {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
        grid-area: show-more;
        text-align: right;
        cursor: pointer;
        padding: 0.7rem;
        line-height: 0;
      }
    }
  }

  .more-info {
    background-color: white;
    border: 2px solid #eee;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 6fr 4fr;
    grid-template-columns: 6fr 4fr;
    -ms-grid-rows: 60px auto 30px;
    grid-template-rows: 60px auto 30px;
    grid-template-areas: "more-info-header more-info-header" "more-info-left more-info-right" "more-info-footer more-info-footer";

    .more-info-header {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
      grid-area: more-info-header;

      .row {
        padding-top: 2rem;

        hr {
          border-color: $hr-color;
        }
      }
    }

    .more-info-left {
      -ms-grid-row: 2;
      -ms-grid-column: 1;
      grid-area: more-info-left;
      text-align: left;
      padding: 1rem;

      .feature {
        padding: 0.5rem;

        .feature-icon {
          float: left;
          height: 100%;

          em {
            padding: 1rem;
            font-size: 2rem;
          }
        }

        .feature-text {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flow-root;
        }
      }

      .table-header {
        cursor: pointer;

        strong em {
          float: right;
        }
      }

      table {
        padding: 0.5rem;
        width: 100%;
        margin-top: 1rem;

        tr {
          border-bottom: 1px solid $hr-color;
          line-height: 4rem;

          td {
            a {
              color: $text-black;
              text-decoration: underline;
            }

            &:last-child {
              float: right;
            }
          }

          &:first-child,
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    .more-info-right {
      -ms-grid-row: 2;
      -ms-grid-column: 2;
      grid-area: more-info-right;
      padding: 1rem;

      .table-header {
        cursor: pointer;

        strong em {
          float: right;
        }
      }

      .action-button {
        padding: 1.5rem;
        font-size: 2rem;
        width: 100%;
        font-weight: 700;
        background-color: $orange;
        border-bottom: 5px solid #e45f2c;
        border-radius: 3px;
        color: white;
        text-decoration: none;
        display: block;

        &:hover {
          background-color: #f57a1d;
          border-color: #f68835;
        }
      }

      .call-action {
        font-size: 2rem;
        color: $orange;
        line-height: 4rem;

        span {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;

          small {
            color: $text-black;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
          }

          strong {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;

            em {
              font-size: 4rem;
            }
          }
        }
      }

      .company-details {
        text-align: left;
      }
    }

    .more-info-footer {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
      grid-area: more-info-footer;
      background-color: #eee;
      font-size: 14px;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: repeat(3, 1fr);
      -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "footer-note1 footer-note2 footer-show-more";

      .footer-note1 {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        grid-area: footer-note1;
        text-align: left;
        padding: 0.7rem;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .footer-note2 {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        grid-area: footer-note2;
        text-align: left;
        padding: 0.7rem;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .footer-show-more {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
        grid-area: footer-show-more;
        text-align: right;
        cursor: pointer;
        padding: 0.7rem;
      }
    }
  }

  &.featured {
    .content {
      border: 2px solid $yellow;
      background-color: $lightest-yellow;
      -webkit-box-shadow: 0px 4px 20px 13px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0px 4px 20px 13px rgba(0, 0, 0, 0.05);
      box-shadow: 0px 4px 20px 13px rgba(0, 0, 0, 0.05);
    }

    .content-column.selected {
      background-color: $light-yellow;
    }

    .lowbar {
      background-color: $yellow;
    }

    .more-info {
      border-color: $yellow;

      .more-info-footer {
        background-color: $yellow;
      }
    }
  }

  &.exclusive {
    .content {
      border: 2px solid #2e86bc;
      background-color: #e6f1f9;
      -webkit-box-shadow: 0px 4px 20px 13px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0px 4px 20px 13px rgba(0, 0, 0, 0.05);
      box-shadow: 0px 4px 20px 13px rgba(0, 0, 0, 0.05);

      .content-column.selected {
        background-color: #cde5f2;

        .info {
          color: #076b9c;
        }
      }

      &.clickable {
        .lowbar {
          background-color: #2e86bc;
          color: white;

          .footnote1,
          .footnote2 {
            span {
              color: white;
            }
          }
        }
      }
    }

    .more-info {
      border-color: #2e86bc;

      .more-info-footer {
        background-color: #2e86bc;
        color: white;
      }
    }
  }
}

.upColumns {
  margin-top: -10px;
}

.downColumns {
  padding-top: 10px;
  color: #312f2e;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #2e86bc;
}

.monthly-cost .m-cgg-icon--i-tooltip {
  font-size: 20px;
}

.product-description {
  padding-top: 5px;
}

@media (max-width: 767.8px) {
  .result {
    .content {
      -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: repeat(3, 1fr);
      -ms-grid-rows: 60px 90px 6rem;
      grid-template-rows: 60px 90px 6rem;
      grid-template-areas: "provider description description" "service channels cost" "lowbar action action";

      .content-column {
        -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        padding: 0;

        span.info {
          line-height: 45px;
        }

        &.selected {
          background-color: inherit;
        }
      }

      .provider {
        grid-template-areas: "providerImage" "providerImage";

        .provider-image img {
          max-height: 100%;
        }
      }

      .product-description {
        grid-area: description;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
        text-align: left;
        padding: 1rem;

        > *:nth-child(1) {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }
      }

      .service {
        grid-template-areas: "serviceType" "coverage";
      }

      .channels {
        grid-template-areas: "noOfChannels" "channelsLabel";
      }

      .cost {
        -ms-grid-row: 1;
        -ms-grid-row-span: 6;
        -ms-grid-column: 5;
        grid-area: cost;
        display: -ms-grid;
        display: grid;
        grid-template-areas: "monthlyCost" "currency";
        -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;

        .monthly-cost {
          border: 0;
        }

        .currency {
          grid-area: currency;
        }
      }

      .dspeed {
        display: none;
      }

      .lowbar {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "show-more";
        background-color: inherit;

        .show-more {
          padding: 1.7rem 0;
          text-align: center;
          font-size: 1.5rem;
          font-weight: 600;
          color: $text-grey;
        }
      }

      .action {
        grid-template-areas: "actionButton";

        .action-button {
          border-bottom: 0;
          height: 100%;
          border-radius: 0;
          margin: 0;
          padding: 1.2rem;
          line-height: 3rem;
        }

        &.v2 {
          grid-template-areas: "actionButton";

          .action-button {
            line-height: 3rem;
            padding: 1.2rem;
            border-bottom: 5px solid #076b9c;
          }
        }
      }

      .banner {
        display: none;
      }
    }

    .more-info {
      -ms-grid-rows: 60px auto auto 60px;
      grid-template-rows: 60px auto auto 60px;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      grid-template-areas: "more-info-header" "more-info-left" "more-info-right" "more-info-footer";

      .more-info-footer {
        -ms-grid-rows: 1fr;
        grid-template-rows: 1fr;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        grid-template-areas: "footer-show-more";
        background-color: inherit;

        .footer-show-more {
          text-align: center;

          span {
            font-size: 2rem;
            font-weight: 600;
            color: $text-grey;
          }
        }
      }
    }

    &.featured .more-info .more-info-footer {
      background-color: inherit;
    }

    &.exclusive {
      .content {
        .content-column.selected {
          background-color: #e6f1f9;

          .info {
            color: #056f9d;
          }
        }

        &.clickable {
          .lowbar {
            background-color: #cde5f2;

            .show-more {
              color: #056f9d;
            }
          }
        }
      }

      .more-info {
        border-color: #2e86bc;

        .more-info-footer {
          background-color: #cde5f2;

          .footer-show-more {
            span {
              color: #056f9d;
            }
          }
        }
      }
    }

    .content {
      .provider {
        -ms-grid-row: 1;
        -ms-grid-row-span: 1;
        -ms-grid-column: 1;

        .provider-image {
          -ms-grid-row: 1;
          -ms-grid-row-span: 2;
          -ms-grid-column: 1;
        }
      }

      .service {
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column: 1;

        .service-type {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }

        .coverage {
          -ms-grid-row: 2;
          -ms-grid-column: 1;
        }
      }

      .channels {
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column: 2;

        .no-of-channels {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }

        .channels-label {
          -ms-grid-row: 2;
          -ms-grid-column: 1;
        }
      }

      .cost {
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column: 3;

        .monthly-cost {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }
      }

      .action {
        -ms-grid-row: 3;
        -ms-grid-row-span: 1;
        -ms-grid-column: 2;
        -ms-grid-column-span: 2;

        .action-button {
          -ms-grid-row: 1;
          -ms-grid-row-span: 1;
          -ms-grid-column: 1;
        }
      }

      .lowbar {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;

        .show-more {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }
      }
    }

    .more-info {
      .more-info-header {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;
      }

      .more-info-left {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
      }

      .more-info-right {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
      }

      .more-info-footer {
        -ms-grid-row: 4;
        -ms-grid-column: 1;
        -ms-grid-column-span: 1;

        .footer-show-more {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }
      }
    }
  }

  @media (max-width: 767.8px) {
    .result .content {
      .product-description {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        -ms-grid-column-span: 2;
      }

      .cost {
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;
        -ms-grid-column: 3;

        .currency {
          -ms-grid-row: 2;
          -ms-grid-column: 1;
        }
      }

      .action.v2 {
        grid-template-areas: "actionButton" "actionButton" "actionButton" "actionButton" "actionButton" "actionButton";

        .action-button {
          padding: 1.5rem;
        }
      }
    }
  }
}
</style>
