<template>
  <div class="container-fluid cja-counter">
    <div class="row">
      <div class="col-12">
        <span class="title">{{ $t("bb.results.filter." + keyName) }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="stepper">
          <button :class="{ 'disabled': value == min }" @click="decValue"><strong>&#8722;</strong></button>
          <span>{{ value }}</span>
          <button :class="{ 'disabled': value == max }" @click="incValue"><strong>&#43;</strong></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gtmEvents from '../../../../mixins/gtm/gtmEventsBBNew'
import { eventBus } from '../../../../main'

export default {
  mixins: [gtmEvents],
  props: ["value", "keyName", "min", "max"],
  methods: {
    incValue() {
      if (this.value < this.max) {
        this.$emit('input', eval(this.value) + 1)
        eventBus.$emit('forcedChange')
        this.sendProductSearch(this.keyName, this.value + 1)
      }
    },
    decValue() {
      if (this.value > this.min) {
        this.$emit('input', eval(this.value) - 1)
        eventBus.$emit('forcedChange')
        this.sendProductSearch(this.keyName, this.value + 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/colors.sass";

.cja-counter {
  padding: 2rem 0;

  .title {
    color: $grey2;
    line-height: 4rem;
  }

  .stepper {
    span {
      line-height: 4rem;
      padding: 0 1rem;
    }

    button {
      border-radius: 50%;
      border: 0.3rem solid #77AA43;
      color: #77AA43;
      width: 3.5rem;
      height: 3.5rem;
      background-color: transparent;
      font-size: 2rem;
      line-height: 1.5rem;
      font-weight: bold;

      &:focus {
        outline: none !important;
      }

      &.disabled,
      &.disabled:hover {
        border-color: #CCCBC8;
        color: #CCCBC8;
        cursor: not-allowed;
      }

      span {
        line-height: 0.5rem;
        color: #312F2E;

        font-size: 2.5rem;
        display: -webkit-inline-box;
        display: -moz-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        padding: 0 2rem;
        width: 5rem;
      }
    }
  }
}

;
</style>